import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'i18n';
import {
  generateQuestion,
  generateDimension,
} from 'surveyUtils';
import { QUESTIONS, TOOLBAR_ITEMS } from 'SurveyConfig';
import {
  addQuestion,
  setCurrentQuestion,
  addDimension,
  updateSurveyDescription,
  updateDimensionDescription,
  updateWelcomeMessage,
} from 'actions';
import LinkedQuestionMaker from './LinkedQuestionMaker';
import { EditorState } from 'draft-js';

const BLOCK_TAB = 'BLOCK_TAB';
const CHILDS_TAB = 'CHILDS_TAB';

const TABS = [
  {
    label: _('Questions'),
    value: BLOCK_TAB,
  },
  {
    label: _('Sub-questions'),
    value: CHILDS_TAB,
  },
];

class CreateBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      showChildQuestions: false,
      tab: BLOCK_TAB,
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  open = () => {
    const { orderDimension, order } = this.props;
    this.props.setCurrentQuestion(orderDimension || 0, order || 0);
    this.setState({ visible: true });
  };

  close = () => {
    this.setState({ visible: false });
  };

  handleClickOutside = event => {
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target) &&
      this.state.visible
    ) {
      this.close();
    }
  };

  handleAddBlock = blockType => {
    const { orderDimension, order, surveyType, language } = this.props;
    this.props.setCurrentQuestion(orderDimension || 0, order || 0);

    switch (blockType) {
      case 'DIMENSION': {
        this.props.addDimension(generateDimension(surveyType));
        break;
      }
      case 'SURVEY_DESCRIPTION': {
        this.props.updateSurveyDescription(language, EditorState.createEmpty());
        break;
      }
      case 'DIMENSION_DESCRIPTION': {
        this.props.updateDimensionDescription('');
        break;
      }
      case 'WELCOME_MESSAGE': {
        const { language } = this.props;
        this.props.updateWelcomeMessage(language, EditorState.createEmpty());
        break;
      }
      default: {
        this.props.addQuestion(generateQuestion(blockType, surveyType));
      }
    }

    this.close();

    if (this.props.unfocus) {
      this.props.unfocus();
    }
  };

  render() {
    const { visible, tab } = this.state;
    const {
      parentQuestion,
      order,
      orderDimension,
      blocks,
      surveyDescription,
      dimensionDescription,
      disableAddDimension,
      disableAddQuestion,
    } = this.props;

    return (
      <div className="add-block" ref={this.wrapperRef}>
        <span className="add-block__button" onClick={this.open}>
          <i className="icomoon-icon icon-tt-plus" />
        </span>
        {visible && (
          <div className="add-block__wrapper">
            {parentQuestion && (
              // show tabs only when question accept childs
              <div className="add-block__tabs">
                {TABS.map(({ label, value }) => (
                  <div
                    key={value}
                    className={tab === value ? 'active' : ''}
                    onClick={() => this.setState({ tab: value })}
                  >
                    {label}
                  </div>
                ))}
              </div>
            )}
            {parentQuestion && tab === CHILDS_TAB ? (
              <LinkedQuestionMaker
                parentQuestion={parentQuestion}
                onClose={this.close}
                order={order}
                orderDimension={orderDimension}
              />
            ) : (
              <>
                <div className="add-block__header">
                  {_('Choose a question')}
                </div>
                <div className="add-block__blocks">
                  {blocks.map(key => {
                    const { color, icon, name } =
                      TOOLBAR_ITEMS[
                        [
                          'SURVEY_DESCRIPTION',
                          'DIMENSION_DESCRIPTION',
                        ].includes(key)
                          ? 'DESCRIPTION'
                          : key
                      ] ?? {};

                    let disabled =
                      (key === 'SURVEY_DESCRIPTION' &&
                        surveyDescription != null) ||
                      (key === 'DIMENSION_DESCRIPTION' &&
                        dimensionDescription != null);

                    if (key === 'DIMENSION') {
                      disabled = disabled || disableAddDimension;
                    }

                    if (QUESTIONS?.[key]) {
                      disabled = disabled || disableAddQuestion;
                    }

                    return (
                      <div
                        className={`add-block__block_item ${
                          disabled ? 'disabled-link' : ''
                        }`}
                        onClick={
                          disabled ? null : () => this.handleAddBlock(key)
                        }
                        key={key}
                      >
                        <div
                          className="block-icon"
                          style={{ backgroundColor: color }}
                        >
                          <span>
                            <i className={icon} />
                          </span>
                        </div>
                        <div className="block-title">{name}</div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  surveyType: state.surveys.list.survey.type,
  surveyDescription: state.surveys.list.survey.description,
  dimensionDescription:
    state.surveys.list.survey.dimensions?.[props.orderDimension]?.description,
  language: state.surveys.list.language,
});

const mapDispatchToProps = {
  addQuestion,
  setCurrentQuestion,
  addDimension,
  updateSurveyDescription,
  updateDimensionDescription,
  updateWelcomeMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBlock);
