import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { NotificationManager } from 'react-notifications';
import {
  participate,
  cancelParticipate,
  duplicateSurvey,
  transformToSectorialTemplate,
} from 'thunks';
import { withRouter } from 'react-router-dom';
import shortId from 'shortid';
import _ from 'i18n';
import { getSurveyLinkPreview, getEmailingCampaignLink } from 'surveyUtils';
import { getAuthorizations } from 'authorizations';
import { SURVEY_MODALS_TYPES, GENERIC_EMAIL_TYPES } from 'constants';
import { TAMTAM_API_LANGUAGES } from 'Common';
import { TTPDropDownMenu, MenuItem, Menu } from 'common/TTPDropDownMenu';
import Open from '../icons/Open';
import Play from '../icons/Play';

const GENERATE_TEMPLATE_FROM_SURVEY = 1;
const TRANSFORM_TO_SECTORIAL = 2;

/**
 * isTemplate: is the value of location.search,
 * it doesn't mean that the current survey is a template
 */
class SurveyActions extends Component {
  constructor(props) {
    super(props);
    const surveyId = props.survey?.id ?? shortId.generate();
    this.dropDownId = `dropdown-${surveyId}`;
    this.popupDeleteId = `popup-delete-${surveyId}`;
    this.popupCloseId = `popup-close-${surveyId}`;
    this.popupStatusId = `popup-status-${surveyId}`;
    this.popupSettingId = `popup-setting-${surveyId}`;
    this.popupTranslationStatusId = `popup-translation-status-${surveyId}`;
    this.popupParticipateId = `popup-participate-${surveyId}`;
    this.popupCancelParticipateId = `popup-cancel-participate-${surveyId}`;
    this.popupGenerateTemplate = `popup-generate-template-${surveyId}`;
  }

  static defaultProps = {
    visible: true,
  };

  /**
   * UNUSED CODE
   */
  handleParticipate = isAccepted => {
    const { id } = this.props.survey;
    this.props
      .participate(id, isAccepted)
      .then(res => {
        this.onSuccess(res, _('participation_msg_success'), _('Participate'));
        this.handleCloseModal();
        this.props.history.push('/surveys?is-template=0');
      })
      .catch(() => {
        this.onFailure();
        this.handleCloseModal();
      });
  };

  handleCancelParticipate = () => {
    const { id } = this.props.survey;
    this.props
      .cancelParticipate(id)
      .then(res => {
        this.onSuccess(
          res,
          _('cancel_participate_msg_success'),
          _('cancel_participate'),
        );
        this.props.history.push('/surveys?is-template=1');
        this.handleCloseModal();
      })
      .catch(() => {
        this.onFailure();
        this.handleCloseModal();
      });
  };

  handleCreateSurveyFromTemplate = () => {
    const msg = _('create_survey_from_template_success');
    const title = _('create_survey');
    const { duplicateSurvey, survey, history } = this.props;
    duplicateSurvey(survey.slug).then(
      res => {
        const newSurvey = res.value.data.data;

        this.onSuccess(res, msg, title);
        if (survey.isFixed) {
          history.push('/surveys?is-template=0');
        } else {
          history.push(`/surveys/${newSurvey.slug}/create`);
        }
      },
      err => this.onFailure(err),
    );
  };

  handleCreateTemplateFromSurvey = () => {
    const msg = _('create_template_from_survey_success');
    const title = _('create_template');
    const { duplicateSurvey, survey } = this.props;
    duplicateSurvey(survey.slug, true)
      .then(res => {
        this.onSuccess(res, msg, title);
        this.handleCloseModal();
        this.props.history.push('/surveys?is-template=1');
      })
      .catch(() => {
        this.onFailure();
        this.handleCloseModal();
      });
  };

  handleTransformToSectorialTemplate = () => {
    const msg = _('transform_to_sectorial_template_success');
    const title = _('create_template');
    const { transformToSectorialTemplate, survey } = this.props;
    transformToSectorialTemplate(survey.slug, true)
      .then(res => {
        this.onSuccess(res, msg, title);
        this.handleCloseModal();
        this.props.history.push('/surveys?is-template=1');
      })
      .catch(() => {
        this.onFailure();
        this.handleCloseModal();
      });
  };

  handleGenrateModel = selected => {
    if (selected === GENERATE_TEMPLATE_FROM_SURVEY) {
      this.handleCreateTemplateFromSurvey();
    } else if (selected === TRANSFORM_TO_SECTORIAL) {
      this.handleTransformToSectorialTemplate();
    }
  };
  /** END UNUSED CODE */

  handleDuplicateSurvey = () => {
    const msg = _('duplicate_survey_success');
    const title = _('Duplicate');
    const { duplicateSurvey, survey } = this.props;
    duplicateSurvey(survey.slug).then(
      res => this.onSuccess(res, msg, title),
      err => this.onFailure(err),
    );
  };

  onSuccess(res, msg, title) {
    if (res.value instanceof Error) {
      this.onFailure();
    } else {
      NotificationManager.success(msg, title);
    }
  }

  onFailure() {
    NotificationManager.error(_('errorOccured'), _('error'));
  }

  render() {
    const {
      loggedAs,
      currentClient,
      survey,
      survey: { slug, type, id, languages, organizationInstance },
      user,
      hidden,
      activeClassName,
      opensType,
    } = this.props;

    const {
      edit,
      preview,
      penetrationRate,
      stats,
      editStatus,
      duplicate,
      publish,
      remove,
    } = getAuthorizations(survey, user, currentClient, loggedAs);

    const linkPreview = getSurveyLinkPreview(
      survey,
      true,
      currentClient?.id ?? 0,
    );
    const hideEdit = hidden?.find(item => item === 'edit');
    const hidePreview = hidden?.find(item => item === 'preview');
    const hidePlay = hidden?.find(item => item === 'play');
    const hideStats = hidden?.find(item => item === 'stats');
    const isSurveyStarted = organizationInstance?.status === 'STARTED';

    return (
      <div className="flex-container float-right">
        <TTPDropDownMenu activeClassName={activeClassName} type={opensType}>
          {!edit.hide && !hideEdit && (
            <MenuItem
              disable={edit.disable}
              onClick={this.props.editSurvey}
              text={_('Edit')}
            />
          )}
          {/* {
            !hidePreview && (
              <MenuItem
                to={`/survey/preview/${slug}`}
                text={_('Preview')}
                isOpenInNewTab={true}
              />
            )
          } */}
          {!preview.hide && !hidePlay && !hidePreview && (
            <MenuItem
              to={linkPreview}
              text={() => (
                <span>
                  <span className="m-r-s">
                    {type == 'QUIZ' ? _('Play') : _('View')}
                  </span>
                  {type == 'QUIZ' ? (
                    <Play fill="#4c4c4c" />
                  ) : (
                    <Open fill="#4c4c4c" />
                  )}
                </span>
              )}
              isOpenInNewTab={true}
            />
          )}
          {!penetrationRate.hide && (
            <MenuItem
              disabled={penetrationRate.disable}
              text={_('penetration_rate')}
              onClick={() =>
                this.props.openSurveyModal(
                  survey,
                  SURVEY_MODALS_TYPES.PENETRATION_RATE,
                )
              }
            />
          )}
          {!stats.hide && !hideStats && (
            <MenuItem
              to={
                type == 'QUIZ' ? `/results/${id}` : `/stats/${slug}?tab=stats&organization=${currentClient?.id ?? 0}`
              }
              text={type == 'QUIZ' ? _('Results') : _('Stats')}
            />
          )}
          {!editStatus.hide && (
            <MenuItem
              disabled={editStatus.disable}
              text={_('Status')}
              onClick={() =>
                this.props.openSurveyModal(survey, SURVEY_MODALS_TYPES.STATUS)
              }
            />
          )}
          {!duplicate.hide && (
            <MenuItem
              onClick={this.handleDuplicateSurvey}
              text={_('Duplicate')}
            />
          )}
          {!publish.hide && (
            <Menu
              menuDirection="left"
              text={_('Publish')}
              disabled={publish.disable || !isSurveyStarted}
              infoTooltip={
                !isSurveyStarted
                  ? {
                      type: 'warning',
                      text: `${_('Change survey status to')}: ${_('STARTED')}`,
                    }
                  : null
              }
            >
              {(languages?.split(',') ?? ['fr']).map(language => (
                <MenuItem
                  key={language}
                  to={getEmailingCampaignLink({
                    communityId: currentClient?.id,
                    targetType: GENERIC_EMAIL_TYPES.SURVEY_INVITATION,
                    language,
                    targetId: id,
                  })}
                  text={`${_('publish in')} ${_(
                    TAMTAM_API_LANGUAGES[language],
                  )}`}
                  isOpenInNewTab={true}
                  isExternal={true}
                />
              ))}
            </Menu>
          )}
          {!remove.hide && (
            <MenuItem
              disabled={remove.disable}
              text={_('Delete')}
              textClassName="alert-color"
              onClick={() =>
                this.props.openSurveyModal(survey, SURVEY_MODALS_TYPES.DELETE)
              }
            />
          )}
        </TTPDropDownMenu>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedAs: state.auth.loggedAs,
  currentClient: state.auth.currentClient,
  user: state.auth.user,
});

const mapDispatchToProps = {
  participate,
  cancelParticipate,
  duplicateSurvey,
  transformToSectorialTemplate,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(SurveyActions);
