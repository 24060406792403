import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateAnswer, addLastUpdatedAnswer } from 'actions';
import { deleteAnswer } from 'thunks';
import _ from 'i18n';
import shortId from 'shortid';
import { isDisplayed } from 'surveyUtils';
import { selectAnswerByQuestion } from 'reducers';
import QuestionChoiceItem from './QuestionChoiceItem';
import { QuestionHeader } from './QuestionHeader';
import PreviewControlButton from './PreviewControlButton';

class BooleanQuestionPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      choices: [1, 0],
      answerValue: props.answer
        ? props.answer.answerValue == 1
          ? 1
          : 0
        : null,
    };
  }

  componentDidUpdate(prevProps) {
    const { parentAnswer, question } = this.props;

    if (
      isDisplayed(question, parentAnswer) !==
      isDisplayed(question, prevProps.parentAnswer)
    ) {
      this.setState({ answerValue: null });
    }
  }

  onChange = (value, isSelected) => {
    this.setState(
      prevState => ({
        ...prevState,
        answerValue: isSelected ? value : null,
      }),
      () => {
        this.updateAnswer();
        if (isSelected) {
          setTimeout(() => {
            this.props.scrollToNext(this.ref);
          }, 0);
        }
      },
    );
  };

  goToNext = goBack => this.props.scrollToNext(this.ref, goBack);

  getValueOfChoice(value) {
    if (this.props.question.type === 'TRUE_FALSE') {
      return value ? _('I accept') : _("I don't accept");
    }
    return value ? _('Yes') : _('No');
  }

  updateAnswer() {
    const {
      updateAnswer,
      question,
      addLastUpdatedAnswer,
      deleteAnswer,
    } = this.props;
    const { answerValue } = this.state;

    const answer = {
      ...this.props.answer,
      question: question.id || question.uid,
      answerValue,
      resourceType: 'boolean',
    };
    updateAnswer(answer);

    if (answer.answerValue != null && answer.answerValue.length !== 0) {
      addLastUpdatedAnswer(question.id || question.uid);
    } else if (answer.id) {
      deleteAnswer(answer.id, question.id);
    }
  }

  renderListChoices() {
    const { choices, answerValue } = this.state;

    return choices.map((value, key) => (
      <QuestionChoiceItem
        key={`choice-${shortId.generate()}`}
        selected={answerValue === value}
        order={key}
        label={this.getValueOfChoice(value)}
        value={value}
        onChange={this.onChange}
      />
    ));
  }

  render() {
    const { question, language, parentAnswer, order } = this.props;
    const { text } = question;
    const isShowed = isDisplayed(question, parentAnswer);
    const hasParent = question.parent != null;

    return (
      <div
        className="preview__container qst__wrapper"
        style={{ display: isShowed ? 'block' : 'none' }}
        ref={ref => {
          this.ref = ref;
          this.props.addRef(ref, null, question);
        }}
      >
        {/* <div className="preview__container__bg" /> */}
        <QuestionHeader
          id={`question-label-${question.id || question.uid}`}
          text={text[language]}
          order={order}
          isRequired={question.required == '1'}
          hasOrder={!hasParent}
        />
        <div className="preview__container__content qst__content">
          <div className="block-choices">
            <div className="choices-column">{this.renderListChoices()}</div>
          </div>
          <PreviewControlButton
            className="srv-button-color--fill"
            onClick={this.goToNext}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { question } = props;
  const questionId = question.id || question.uid;

  return {
    language: state.surveys.list.language,
    answer: state.surveyResponse.answers.hasOwnProperty(questionId)
      ? state.surveyResponse.answers[questionId]
      : null,
    parentAnswer: selectAnswerByQuestion(question.parent)(state.surveyResponse),
  };
};

const mapDispatchToProps = { updateAnswer, addLastUpdatedAnswer, deleteAnswer };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BooleanQuestionPreview);
