export default {
  home: 'Tamtam Survey',
  signIn: 'Aanmelden / Inschrijven',
  'About us': 'Over ons',
  owrApps: 'OWR Apps',
  pageNotFound: 'Oeps, zou deze pagina kan niet gevonden!',
  goBackToHome: 'Ga terug naar de startpagina',
  logout: 'uitloggen',
  help: 'helpen',
  Communities: 'Gemeenschappen',
  campaigns: 'Campagnes',
  createCampaign: 'Maak campagne',
  webPage: 'webpagina',
  previous: 'Voorgaand',
  next: 'Volgend',
  filter: 'Filter',
  status: 'Staat',
  DRAFT: 'ONTWERP',
  SENT: 'VERZONDEN',
  CREATED: 'CREËERT',
  language: 'Taal',
  reset: 'Reset',
  searchBySubject: 'Zoek op onderwerp',
  subject: 'Subject',
  cancel: 'ANNULEER',
  save: 'Opslaan',
  codeOwn: 'je eigen code',
  createdAt: 'Gemaakt bij',
  updatedAt: 'Bijgewerkt om',
  endedAt: 'Beëindigd om',
  startedAt: 'Begon bij',
  groups: 'Groepen',
  recipients: 'Ontvangers',
  sent: 'Verzending',
  enterText: 'Voer tekst ...',
  deleteCampaignConfirm: 'Wil je echt wilt de campagne wilt verwijderen?',
  delete: 'verwijderen',
  fromEmail: 'Van email',
  fromName: 'Van naam',
  replyTo: 'antwoord aan',
  recipientType: 'Ontvanger Type',
  settings: '',
  typeTestEmail:
    'Typ de auquelles mail adressen die u wilt op de proef te sturen',
  warning: 'warning',
  groupsIncluded: 'groepen opgenomen',
  excludedGroups: 'GROEPEN UITGESLOTEN',
  selectGroups: 'Selecteer Groepen',
  excludeGroups: 'uitsluiten groepen',
  'Select event': 'Selecteer evenement',
  'Select activities': 'Selecteer activiteiten',
  'Exclude activities': 'Activiteiten uitsluiten',
  'Select status': 'Selecteer de status',
  'Exclude status': 'Status uitsluiten',
  sendTitle: 'Je staat op het punt om een campagne naar te sturen',
  tested: 'getest',
  attachments: 'toebehoren',
  confirm: 'BEVESTIGEN',
  confirmSendCampaign: 'Weet u zeker dat u deze campagne wilt verzenden?',
  noNotNow: 'NEE, NIET NU',
  yesSend: 'JA, ZEND',
  sendTestTo: 'Stuur de test',
  sendTest: 'STUUR TEST',
  send: 'sturen',
  PLAIN_TEXT: 'TEKST',
  WEB_PAGE: 'WEB PAGINA',
  selectTarget: 'Selecteer gerichte doelgroepen',
  campaignInfo: 'Campagne info',
  invalidObject:
    'object moet minimaal 5 tekens bevatten en mag niet meer dan 200 tekens bevatten',
  invalidFromName: 'Ongeldige naam, vul dan meer dan 4 karakters',
  invalidEmail: 'e-mailadres ongeldig',
  emailAdd: 'Voeg e-mails toe en typ ENTER',
  userAdd: 'Voeg een gebruiker toe en tik op ENTER',
  deleteCampaignSuccess: 'succesvolle verwijdering',
  campaignDeleted: 'De campagne werd succesvol verwijderd',
  campaignTestSent: 'Een test e-mail is succesvol verzonden',
  campaignTestSuccess: 'succesvolle test',
  campaignSent: 'De campagne is verzonden',
  campaignSentSuccess: 'het verzenden van een succesvolle',
  campaignAdded: 'De campagne is opgeslagen',
  addCampaignSuccess: 'toe te voegen',
  successfulAction: 'Succesvolle actie',
  operationCompleted: 'Actie uitgevoerd met succes',
  errorOccured: 'Er is een fout opgetreden, Gelieve te proberen later.',
  incompleteOperation:
    'Kan de operatie niet voltooien, onjuiste gegevens zijn ingediend',
  invalidUrl: 'Kan de bewerking te voltooien, werd een ongeldige URL ingediend',
  error: 'fout',
  enterUrl: 'URL invoeren',
  search: 'Zoeken',
  deliveredTo: 'Bezorgd bij',
  emailsOpen: 'E-mails open',
  linkClicks: 'Links klikken',
  Bounce: '',
  Spam: '',
  recipientsMessage: 'dit filter beschouwt taal :',
  pauseCampaign:
    'De onderbreking van de campagne voorkomt het verzenden <br> van meer e-totdat het wordt gereactiveerd',
  pauseSending: 'elke onderbreking bevordert e-mails verzenden',
  stopCampaign: 'zeker stoppen met het sturen campagne',
  resume: 'overzicht',
  register_from_email:
    'Klik hier om via Amazon een e-mail te sturen via Amazon, klik op VALIDER voor meer informatie over het proces.',
  reply_aws_email:
    'Er is een email gestuurd naar Amazon, Antwoord en Vernieuwen',
  replyToError:
    'Vul een geldig e-mail of tekst in de indeling : naam <email@example.com>',
  validate: 'bevestigen',
  refresh: 'verversen',
  GUEST: 'GAST',
  SPEAKER: 'SPREKER',
  CONTACT: '',
  PARTNER: '',
  PROSPECT: 'VOORUITZICHT',
  templateChoices: 'Selecteer een sjabloon',
  'first name': 'Voornaam',
  'last name': 'achternaam',
  stopCampaignConfirm: 'Wil je deze campagne zeker stoppen?',
  stop: 'HOU OP',
  Event: 'Gebeurtenis',
  'Included activities': 'Ingesloten activiteiten',
  'Excluded activities': 'Uitgesloten activiteiten',
  'Included status': 'Ingesloten status',
  'Excluded status': 'Uitgesloten status',
  Formulas: 'Formules',
  Slots: '',
  Options: 'Opties',
  All: 'ALLE',
  templates: 'De modellen',
  NEXT: 'VOLGENDE',
  nothing_found: 'Oeps, Er zijn geen resultaten gevonden voor uw zoekopdracht',
  yes: 'ja',
  no: 'neen',
  french: 'frans',
  english: 'engels',
  dutch: 'nederlands',
  overview: 'overzicht',
  links: 'hyperlinks',
  approvals: 'goedkeuringen',
  approval_preferences: 'Goedkeuringsvoorkeuren voor een e-mailcampagne',
  'send me': 'stuur me',
  'send to approvers': 'stuur naar goedkeurders',
  'the approvers': 'de fiatteurs',
  'Send your campaign immediately': 'Verstuur uw campagne onmiddellijk',
  'Schedule sending of your campaign for a specific time':
    'Plan het verzenden van uw campagne voor een specifieke tijd',
  'the test is obligatory': 'de test is verplicht',
  'automatic sending': 'automatisch verzenden',
  'the minimum number of acceptance': 'het minimum aantal acceptaties',
  automatic_sending_paragraph:
    'staat het automatisch verzenden van de campagne toe bij het bereiken van het limietnummer zonder enige afwijzing',
  test_obligatory_paragraph:
    'de campagne wordt pas verzonden nadat een test is verzonden',
  minimum_acceptance_paragraph:
    "le nombre minimum de validation est requis pour l'envoi automatique",
  no_approver_found: 'je hebt de goedkeurders nog niet gedefinieerd',
  'Add an approver': 'Geen unnambateur',
  'delivery date': 'datum en tijdstip van verzending',
  'Drag and drop some files here, or click to select files to upload.':
    'Sleep enkele bestanden hier, of klik op om bestanden te selecteren om te uploaden.',
  Attachments: 'toebehoren',
  attachment: 'gehechtheid',
  reason: 'reden',
  'Thank you for approving this campaign':
    'Bedankt voor het goedkeuren van deze campagne',
  'your acceptance is taken into consideration':
    'uw acceptatie wordt in aanmerking genomen',
  'please specify the reason for rejection': 'geef de reden voor afwijzing op',
  about_us_content:
    'is een geheel van tools die een bepaalde groep toelaat om te communiceren, uitwisselen, organiseren en doeltreffend samenwerken. Het platform bevat verschillende applicaties samengevoegd naargelang de noden van de klant. Dankzij deze tools, beschikken onze klanten over de laatste technologie.',
  'No approval request is sent for this release':
    'Er is geen goedkeuringsverzoek verzonden voor deze release',
  "approvers' feedback for the test version":
    'feedback van fiatteurs voor de testversie',
  preview: 'voorvertoning',
  upload: 'uploaden',
  apply: 'toepassen',
  close: 'dichtbij',
  'Once you close your changes will be lost. Do you really want to continue':
    'Je wijzigingen gaan verloren. Wil je echt doorgaan?',
  'search by title': 'zoeken op titel',
  none: 'geen',
  'last modified on': 'laatst gewijzigd op',
  by: 'door',
  replicate: 'kopiëren',
  original: 'origineel',
  replication: 'kopiëren',
  'Apply authorization to selected users':
    'Toestemming toepassen op geselecteerde gebruikers',
  Apply: 'Toepassen',
  'Add new user': 'Voeg een nieuwe gebruiker toe',
  'Select All': 'Selecteer alles',
  'Deselect All': 'Tout déselectionner',
  'allow deletion': 'laat verwijdering toe',
  'allow change': 'schakel verandering in',
  'Are you sure you want to delete this template':
    'Weet je zeker dat je deze sjabloon wilt verwijderen',
  'Allow use for all users': 'Gebruik voor alle gebruikers toestaan',
  'Allow use for certain users': 'Gebruik voor sommige gebruikers toestaan',
  'user preferences': 'gebruikersvoorkeuren',
  'Add an image for this template':
    'Voeg een afbeelding toe voor deze sjabloon',
  privilege: 'privilege',
  lists: 'lijsten',
  'create list': 'maak een lijst',
  'search by name': 'zoeken op naam',
  subscribers: 'abonnees',
  'mandatory list': 'verplichte lijst',
  name: 'naam',
  Edit: 'Uitgeven',
  Duplicate: 'Duplicaat',
  Publish: 'Publiceren',
  Stats: 'Statistiek',
  Results: 'Uitslagen',
  Delete: 'Verwijderen',
  Status: 'Staat',
  remove_block_message: 'Weet je zeker dat je dit veld wilt verwijderen?',
  save_survey_success: 'De vragenlijst is succesvol geregistreerd',
  close_survey_message: 'Wilt u deze vragenlijst echt sluiten?',
  close_survey_success: 'De vragenlijst is succesvol afgesloten',
  delete_survey_message:
    'Weet je zeker dat je deze vragenlijst wilt verwijderen?',
  delete_survey_success: 'De vragenlijst is succesvol verwijderd',
  delete_question_message: 'Weet je zeker dat je deze vraag wilt verwijderen?',
  delete_question_success: 'De vraag is succesvol verwijderd',
  delete_dimension_message:
    'Weet u zeker dat u deze dimensie wilt verwijderen?',
  delete_dimension_success: 'De dimensie is met succes verwijderd',
  delete_choice_message: 'Weet je zeker dat je deze keuze wilt verwijderen?',
  delete_choice_success: 'De keuze is succesvol verwijderd',
  delete_survey_theme_message:
    'Weet je zeker dat je dit thema wilt verwijderen?',
  delete_survey_theme_success: 'Het thema is succesvol verwijderd',
  survey_closed_message:
    'Bedankt voor uw tijd, helaas is deze enquête gesloten / gesloten',
  survey_not_started_message:
    'Bedankt voor je tijd, helaas is deze enquête nog niet beschikbaar',
  close_survey: 'Sluit de vragenlijst',
  language_not_available:
    'Uw taal wordt nog niet ondersteund. Selecteer een taal in de lijst',
  Close: 'Sluiten',
  'New survey': 'Nieuwe enquête',
  'My WorkSpace': 'Mijn werkruimte',
  Surveys: 'Onderzoeken',
  Description: 'Beschrijving',
  Dimension: 'dimensie',
  'Text Input': 'Tekstinvoer',
  'Multiple selection': 'Meerdere selectie',
  'Single Selection': 'Enkele selectie',
  'Number Input': 'Nummerinvoer',
  Legal: 'Wettelijk',
  'Yes/No': 'Ja/Nee',
  Rating: 'Evaluatie\n',
  'Opinion scale': 'Schaal van mening',
  Question_input: 'Voer hier uw vraag in ...',
  Save: 'Opnemen',
  Preview: 'Overzicht',
  Title: 'Titel',
  Survey_title: 'Titel van de vragenlijst',
  ENTER: 'BINNENKOMEN',
  'I accept': 'Ik accepteer',
  "I don't accept": 'Ik accepteer het niet',
  Yes: 'Ja',
  No: 'Neen',
  press: 'Of klik op',
  min_number_error: 'Voer een getal in dat groter is dan',
  max_number_error: 'Voer een nummer in dat kleiner is dan',
  between_number_error: 'Voer een getal in tussen',
  length_invalid_message:
    'Deze waarde is te kort. Het moet 2 tekens of meer bevatten.',
  length_3_invalid_message:
    'Deze waarde is te kort. Het moet 3 tekens of meer bevatten.',
  invalid_answer_message: 'Antwoord niet geldig',
  required_answers_message: 'Vul alstublieft de verplichte velden in',
  answers_not_valid_message: 'Corrigeer de gemarkeerde velden',
  and: 'en',
  Answer_input: 'Voer hier uw antwoord in',
  'Thanks for completing this survey !':
    'Bedankt voor het reageren op deze enquête',
  Start: 'Begin',
  'to make a line break': 'Om een regel te breken',
  Submit: 'Doorsturen',
  Required: 'Verplichte velden',
  'Vertical Alignment': 'Verticale uitlijning',
  'Min Number': 'Min nummer',
  'Max Number': 'Max nummer',
  Shape: 'Vorm',
  Steps: 'Stappen',
  searchByOrganizationName: 'Zoeken op organisatienaam',
  ResultsByOrganization: 'resultaten Van Organization',
  'Filter by': 'Filteren op',
  'Ranking Clients of Organization': 'Ranglijst van Organization',
  'Ranking Clients': 'Klanten rangschikken',
  'Ranking Fiduciaries': 'Fiduciaires rangschikken',
  "My Organization's ranking": 'Mijn vertrouwenspersoon',
  'Languages score': 'Talen score',
  'Translation status': 'Vertaal status',
  'Survey status': 'Enquête status',
  'out of': 'uit',
  'people answered this question': 'mensen hebben deze vraag beantwoord',
  'Total number of responses': 'Totaal aantal antwoorden',
  'Nobody answered this question yet': 'Niemand heeft deze vraag al beantwoord',
  responses: 'reacties',
  response: 'antwoord',
  create_survey: 'Maak een enquête',
  create_template: 'Sjabloon genereren',
  create_template_from_survey: 'Integreer de bibliotheek',
  create_survey_from_template_success:
    'De enquête is met succes gemaakt van dit model',
  'Save as template': 'opslaan als sjabloon',
  "Tamtam's surveys": "Tamtam's enquêtes",
  'My surveys': 'Mijn enquêtes',
  duplicate_survey_success: 'De enquête is met succes gedupliceerd',
  user_has_already_responded_title: 'U hebt deze vragenlijst al beantwoord',
  user_has_already_responded_message:
    'U kunt dit formulier slechts één keer invullen. Neem contact op met de eigenaar van het formulier als u denkt dat dit een vergissing is',
  edit_penetration_rate: 'bewerk penetratiegraad',
  Settings: 'Instellingen',
  'Set Penetration Rate': 'Stel de penetratiesnelheid in',
  user_not_client_of_organization_title:
    'je bent geen klant van een fiduciaire',
  user_not_client_of_organization_message:
    'U kunt dit formulier slechts één keer invullen. Neem contact op met de eigenaar van het formulier als u denkt dat dit een vergissing is',
  Back: 'terugkeer',
  'Template State': 'Template Staat',
  Saved: 'opgeslagen',
  Survey_Template: 'Enquête model',
  Validate: 'Bevestigen',
  'Score by Dimension': 'Score per Dimensie',
  'Satisfaction Template': 'Tevredenheids model',
  'Custom Template': 'Aangepast model',
  'Building a Survey': 'Een onderzoek opbouwen',
  Describe_survey_tamtam_app:
    'TamtamSurvey is de eiasetmanier om enquêtes te maken en antwoorden te krijgen.TamtamSurvey laat je snel een enquête opvouwen en doelantwoorden krijgen van het publiek dat je wilt vragen.',
  'Not just a Pretty interface': 'Niet alleen een mooie interface',
  'Impress your audience with our plan':
    'Maak indruk op je publiek met ons plan',
  'A gorgeous, smooth interface that keeps people engaged.':
    'Een prachtige, vloeiende interface die mensen betrokken houdt.',
  'See every change you make in real-time with Live Preview.':
    'Bekijk elke wijziging die u in realtime aanbrengt met Live Preview.',
  'Logic Jump makes each form smarter and more personal.':
    'Logic Jump maakt elke vorm slimmer en persoonlijker',
  ACCOMPLISHED: 'VERWEZENLIJKTE',
  'IN PROGRESS': 'BEZIG',
  Questions: 'Vragen',
  'linked to': 'gekoppeld aan',
  'Add a sub question': 'Voeg een subvraag toe',
  'Add question': 'Vraag toevoegen',
  'Required previous answer is': 'Vereist vorige antwoord is',
  'Choose a question': 'Kies een vraag',
  Profiling: 'Profilering',
  not_valid_email: 'Dit e-mailadres is niet geldig',
  'Please enter your Email': 'Vul alstublieft uw e-mail adres in',
  popup_survey_status_message: 'Verander de status van de enquête',
  edit_survey_status: 'De status van de enquête is met succes gewijzigd',
  srv_start: 'Begin',
  'Profiling Filters': 'Profileerfilters',
  'search value': 'zoekwaarde',
  Language: 'Taal',
  Languages: 'Talen',
  ' people answered by this option': 'mensen beantwoordden met deze optie',
  'Response Summary': 'Resultaten van de bevraging',
  Processing: 'Verwerken',
  msg_template_not_exist: 'Dit type model is nog niet beschikbaar',
  'Please refresh this page': 'Vernieuw deze pagina',
  'Results are being prepared': 'Resultaten worden voorbereid',
  opinionSurvey: 'opiniepeiling',
  satisfactionSurvey: 'Gemeten enquête',
  customSurvey: 'Aangepaste enquête',
  PENDING: 'IN AFWACHTING',
  STARTED: 'BEFONNEN',
  ENDED: 'BEËINDIGD',
  CLOSED: 'GESLOTEN',
  SATISFACTION: 'TEVREDENHEID',
  OPINION: 'MENING',
  CUSTOM: 'PERSOONLIJK',
  private: 'privaat',
  public: 'openbaar',
  owner: 'eigenaar',
  'Updated At': 'Gewijzigd op',
  nbResponses: 'Aantal antwoorden',
  'Template gallery': 'Sjabloongalerij',
  template_picker_infos:
    'Kies een sjabloon of klik helemaal opnieuw op Start om een geheel nieuwe enquête te maken.',
  'Start from scratch': 'Beginnen vanaf nul',
  'Use this template': 'Gebruik deze sjabloon',
  'write yours': 'schrijf de jouwe',
  'create from template': 'maak van model',
  'Edit answer': 'Bewerk antwoord',
  Participate: 'Deelnemen',
  cancel_participate: 'Deelname annuleren',
  'Already answered': 'Al beantwoord',
  CHIEF_EDITOR: '',
  AUTHOR: '',
  REDACTOR: '',
  LEGAL_REPRESENTATIVE: 'WETTELIJKE VERTEGENWOORDIGER',
  MANAGER: 'MANAGER',
  createSurvey: 'enquête maken',
  editableResponse: 'Het antwoord is aanpasbaar',
  no_survey_data_msg: 'Geen overeenkomende items gevonden',
  no_survey_data_info: 'Controleer de filterinstellingen',
  all: 'alle',
  penetration_rate: 'Penetratiegraad',
  isTemplate: 'Enquêtesjabloon',
  isSectorial: 'Geconsolideerde enquête',
  ifixed: 'Niet-gepersonaliseerde enquête',
  obligatoryRanking: 'Verplichte rangorde',
  consolidatedResults: 'Geconsolideerde resultaten',
  'Consolidated survey': 'Geconsolideerde enquête',
  participation_msg_success: 'Je hebt met succes deelgenomen',
  cancel_participate_msg_success: 'De deelname is succesvol geannuleerd',
  cancel_participate_msg: 'Wil je echt je deelname annuleren?',
  participate_msg: 'Weet u zeker dat u wilt deelnemen aan deze enquête??',
  participate_opinion_msg:
    'Bent u het ermee eens om de resultaten van uw organisatie te delen?',
  participate_satisfaction_msg: 'Accepteert u de rangorde van uw organisatie?',
  create_template_modal_msg: 'Kies een bewerking',
  'Transform into a consolidated survey':
    'Transformeer in een geconsolideerde enquête',
  transform_to_sectorial_template_success: 'De sjabloon is succesvol gemaakt',
  "You can't send answer in a template":
    'Je kunt geen antwoord in een sjabloon verzenden',
  'Add welcome screen': 'Welkomstscherm toevoegen',
  'Add banner': 'Voeg banner toe',
  Add: 'Toevoegen',
  Update: 'Bijwerken',
  Library: 'Bibliotheek',
  Organization: 'Organisatie',
  duplicate_into_library: 'Dupliceer in de bibliotheek',
  themes: "thema's",
  Editor: 'Editor',
  design: 'ontwerp',
  'Public themes': "Publieke thema's",
  'My themes': "Mijn thema's",
  Customize: 'Aanpassen',
  'Create new Theme': 'Maak een nieuw thema',
  'Welcome message': 'Welkoms bericht',
  'Rating choice': 'Rating keuze',
  choice: 'keuze',
  'Survey description': 'Enquête beschrijving',
  Revert: 'Terugkeren',
  Ranking: 'Ranking',
  Responses: 'Antwoorden',
  stats_question_no_data_found_msg:
    'Als je dorstig bent naar sommige antwoorden, maak dit dan een vereiste vraag in de vraaginstellingen',
  stats_survey_no_data_found_msg: 'Deze enquête heeft nog geen reacties.',
  'No signs of movement': 'Geen tekenen van beweging',
  'No campaign was created yet!': 'Er is nog geen campagne gemaakt',
  Targets: 'Doelen',
  Object: 'Voorwerp',
  'Campaign stats': 'Campagnestatistieken',
  'Ranking client': 'Klanten rangschikken',
  'Ranking organizations': 'Ranking van organisaties',
  Participants: 'Deelnemers',
  'My organization': 'Mijn organisatie',
  SURVEY_INVITATION: 'Uitnodigingssjabloon',
  SURVEY_REMINDER: 'Herinneringssjabloon',
  SURVEY_PARTIAL_REMINDER: 'Gedeeltelijke herinneringssjabloon',
  SURVEY_RESULTS: 'Resultaten sjabloon',
  SURVEY_THANK: 'Bedankt sjabloon',
  'Emails Templates': 'Sjablonen voor e-mails',
  'Customize your email Templates': 'Pas uw e-mailsjablonen aan',
  subscribe_to_stats_msg:
    'Wilt u statistieken ontvangen zodra deze beschikbaar zijn ?',
  other: 'anders',
  'Public stats': 'Openbare statistieken',
  'Copied to clipboard': 'Gekopieerd naar het klembord',
  Invited: 'Uitgenodigd',
  Revival: 'Herleving',
  revival: 'herleving',
  PartialRevival: 'Gedeeltelijk herstel',
  Participated: 'Deelgenomen',
  Thanks: 'dank',
  STATUS_RESULTS_NOT_ASKED: 'Niet gevraagd',
  STATUS_RESULTS_RECEIVED: 'Ontvangen',
  STATUS_RESULTS_ASKED: 'Gevraagd',
  'Not participated': 'Niet deelgenomen',
  'Not received': 'Niet ontvangen',
  Received: 'Ontvangen',
  Uninvited: 'Ongevraagd',
  Manage: 'Beheren',
  'Complete responses': 'Volledige antwoorden',
  'Incomplete responses': 'Onvolledige antwoorden',
  guests: 'gasten',
  results: 'uitslagen',
  'Suggested Campaigns': 'Voorgestelde campagnes',
  'Show all results': 'Toon alle resultaten',
  'Delete dimension': 'Dimensie verwijderen',
  'Delete question': 'Vraag verwijderen',
  'Delete choice': 'Selectie verwijderen',
  'no template is available in this language':
    'geen sjabloon is beschikbaar in deze taal',
  'Mandatory acknowledgment mail': 'Verplicht bevestigingsbericht',
  'Send Test emails to': 'Stuur Test-e-mails naar',
  'Apply a template': 'Een sjabloon toepassen',
  Community: 'Gemeenschap',
  from_email_not_registred:
    'Klik hier om via Amazon een e-mail te sturen via Amazon',
  Note: 'Noot',
  'Language unavailable': 'Taal niet beschikbaar',
  'Emails templates': 'E-mailsjablonen',
  'Integrate the English language': 'integreer de Engelse taal',
  'Integrate the French language': 'Integreer de Franse taal',
  'Integrate the Dutch language': 'Integreer de Nederlandse taal',
  'Edit combination': 'Bewerk combinatie',
  'Duplicate the model': 'Dupliceer het model',
  'Initialize the mails': 'Initialiseer de mails',
  'Results not asked': 'Resultaten niet gevraagd',
  'User has already received results':
    'Gebruiker heeft al resultaten ontvangen',
  'Missing generic emailing of language':
    'Generiek e-mailen van taal ontbreekt',
  'User has already completed the survey':
    'Gebruiker heeft de enquête al ingevuld',
  'The maximum number of reminders has been exceeded':
    'Het maximale aantal herinneringen is overschreden',
  'User was invited at': 'Gebruiker was uitgenodigd om',
  'you can send reminder after': 'u kunt daarna een herinnering sturen',
  'User already received a reminder at':
    'De gebruiker heeft al een herinnering ontvangen op',
  years: 'jaar',
  months: 'maanden',
  days: 'dagen',
  hours: 'uur',
  minutes: 'notulen',
  seconds: 'seconden',
  'a year': 'een jaar',
  'a month': 'een maand',
  'a day': 'een dag',
  'an hour': 'een uur',
  'a minute': 'een minuut',
  'a few seconds': 'een paar seconden',
  'Send results email': 'Stuur resultaten e-mail',
  'Send a reminder email': 'Stuur een herinneringsmail',
  'Send a partial reminder email': 'Stuur een gedeeltelijke herinneringsmail',
  Emails: 'E-mails',
  'Notifications of updates': 'Meldingen van updates',
  'Nothing to show': 'Niets te tonen',
  'TAMTAM applications': 'TAMTAM-toepassingen',
  cannot_delete_answered_question:
    'U kunt de vragen die zijn beantwoord niet verwijderen.',
  Date: 'Datum',
  'Initial stats': 'Eerste statistieken',
  complete: 'compleet',
  incomplete: 'onvolledig',
  population: 'bevolking',
  Invitation: 'Uitnodiging',
  'New campaign': 'Nieuwe campagne',
  'Revival has already been sent': 'De verhoging is al verzonden',
  'The reminders sent': 'De herinneringen verzonden',
  create: 'maak een',
  answers: 'antwoorden',
  'Please Choose An Available Language': 'Kies een beschikbare taal',
  'Log in': 'inloggen',
  'Please enter your email address': 'Voer je e-mailadres in',
  'Already have an account': 'Heb al een account',
  email: 'e-mail',
  Continue: 'Doorgaan met',
  'well done': 'bravo',
  'you have completed your survey': 'u hebt uw enquête ingevuld',
  'Do you want to receive statistics ?': 'Wilt u statistieken ontvangen ?',
  'Create your custom satisfaction survey.':
    'Maak uw gepersonaliseerde tevredenheidsonderzoek.',
  'Create your custom poll survey.': 'Maak uw aangepaste enquête-enquête.',
  'Create your custom quiz survey.': 'Maak je gepersonaliseerde quiz.',
  'Join with the Event Mobile app': 'Doe mee met de Event Mobile-app',
  'with Game PIN': 'met Game PIN',
  Players: 'Spelers',
  Play: 'Spelen',
  'Game PIN': 'PIN spel',
  exit: 'vertrekken',
  'Top ten': 'Top tien',
  'please configure quiz display': 'configureer de quizweergave',
  'select the display type': 'sselecteer het weergavetype',
  'select the languages to display': 'selecteer de weer te geven talen',
  bilingual: 'tweetalig',
  monolingual: 'eentalige',
  'Select a game': 'Selecteer een spel',
  'Please select a game': 'Selecteer een spel',
  'Add "Other" option': 'Optie "Overig" toevoegen',
  time: 'tijd',
  'Show game PIN to public': 'Toon spel-PIN aan publiek',
  'Show PIN': 'Toon PIN',
  'Portal question': 'Portalvraag',
  'Create your custom portal question.': 'Maak uw aangepaste portaalvraag.',
  'Start date': 'Begin datum',
  'End date': 'Einddatum',
  COLLABORATORS: 'MEDEWERKERS',
  CLIENTS: 'KLANTEN',
  ALL: 'ALLE',
  EXTRA_CROSS: 'Extra Cross',
  INTRA_CROSS: 'Intra Cross',
  EXTRA_SHARE: 'Extra Share',
  INTRA_SHARE: 'Intra Share',
  'CREATE, MANAGE AND SEND YOUR SURVEYS':
    'MAAK, BEHEER EN VERZEND UW ONDERZOEKEN',
  Remove: 'Verwijderen',
  'My Library': 'Mijn bibliotheek',
  'Public link': 'Publieke link',
  'Start at': 'Begin bij',
  'End at': 'Eindig op',
  'Shared survey': 'Gedeelde enquête',
  isFixed: 'Niet-gepersonaliseerde enquête',
  'Clients satisfaction': 'Klanten tevredenheid',
  Client: 'Cliënt',
  'publish in': 'publiceren in het',
  campaign: 'campagne',
  'Collaborators satisfaction': 'Medewerkers tevredenheid',
  'Confirm delete': 'Bevestig verwijderen',
  'Change survey status to': 'Wijzig enquêtestatus in',
  'Hide profiling if known': 'Verberg profilering indien bekend',
  'Please fill in this field': 'Gelieve dit veld in te vullen',
  'Participation of non-members': 'Deelname van niet-leden',
  'Anonymous survey': 'Anonieme enquête',
  'Hidden name': 'Verborgen naam',
  members_only_message: 'Deze enquête is alleen beschikbaar voor leden',
  anonymous_message:
    'U gaat deelnemen aan een onderzoek waarvan de anonimiteit absoluut gegarandeerd is. Het enige doel van het e-mailadres is het garanderen van een uniek antwoord (1 antwoord per deelnemer). Het is nooit mogelijk vast te stellen wie wat heeft beantwoord.',
  anonymous_thanks_message: 'Alvast bedankt voor uw deelname!',
  'Anonymous User': 'Anonieme gebruiker',
  profiling_anonymous_message:
    'Deze enquête is anoniem. De gedeclareerde profilering wordt alleen gebruikt voor statistische doeleinden',
  Profession: 'Beroep',
  Age: 'Leeftijd',
  'Postal code': 'Postcode',
  Country: 'Land',
  'Do you really want to leave this page': 'Wil je deze pagina echt verlaten',
  'Confirm action': 'Actie bevestigen',
  View: 'Visie',
  'THANK YOU !': 'HARTELIJK DANK !',
  'Thank you for devoting time to this survey.':
    'Bedankt voor de tijd die u aan deze enquête heeft besteed.',
  'Did you save all the changes?': 'Heb je alle wijzigingen opgeslagen?',
  'Sub-questions': 'Subvragen',
  INVITATION_CAMPAIGN: 'UITNODIGING',
  REVIVAL_CAMPAIGN: 'HERINNERING',
  PARTIAL_REVIVAL_CAMPAIGN: 'GEDEELTELIJKE HERINNERING',
  SURVEY_RESULTS_CAMPAIGN: 'RESULTAAT',
  PARTICIPATIONS_CAMPAIGN: 'DEELNAME',
  'Add the non-member to community': 'Voeg het niet-lid toe aan de community',
  'I Agree to the terms of service': 'Ik ga akkoord met de servicevoorwaarden',
  'Join the community': 'Word lid van de gemeenschap',
  'create survey': 'enquête maken',
  'Easily create, distribute and analyze your surveys!': 'Creëer, verspreid en analyseer eenvoudig uw enquêtes!',
  'invalid postal code': 'ongeldige postcode',
};
