import React, { Component, Fragment } from 'react';
import { setCurrentQuestion, stopAutoSave } from 'actions';
import shortId from 'shortid';
import { connect } from 'react-redux';
import {
  doScrollElement,
  scrollTo,
  getScrollTop,
  getHeight,
  isElementInViewport,
  isElementInCenterPreview,
} from 'scroll';
import _ from 'i18n';
import { windowHeight } from 'Common';
import SurveyTitlePreview from 'preview-items/SurveyTitlePreview';
import DimensionPreviewList from 'preview-items/DimensionPreviewList';
import PreviewMessage from 'preview-items/PreviewMessage';
import { TTP_API_URL } from 'Config';
import { getAllQuestionsOfSurvey } from 'question';
import SubmitResponse from './preview-items/SubmitResponse';
import PreviewHeader from './preview-items/PreviewHeader';
import TopBar from './preview-items/TopBar';
import Profiling from './preview-items/profiling/Profiling';

const ANIMATION_DURATION = 300;
const HIDE = 'hide';
const SHOW = 'show';
const HIDDEN_OPACITY = 0.15;

class Preview extends Component {
  constructor(props) {
    super(props);
    this.listRefs = [];
    this.listDimensionsRefs = [];
    this.currentDimensionIndex = -1;
    this.dimensionOverlay = [];
    this.addRef = this.addRef.bind(this);
    this.scrollToNext = this.scrollToNext.bind(this);
    this.addDimensionOverlayRef = this.addDimensionOverlayRef.bind(this);
    this.removeRef = this.removeRef.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.resetListRefs = this.resetListRefs.bind(this);
    this.missingQuestionRef = null;
    this.state = {
      missingQuestion: null,
    };
  }

  componentDidMount() {
    if (this.props.scrollBody) {
      window.addEventListener('scroll', this.handleScroll);
    } else {
      this.preview = document.getElementById('scrollPreview');
      this.preview.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    if (this.props.scrollBody) {
      window.removeEventListener('scroll', this.handleScroll);
    } else {
      this.preview.removeEventListener('scroll', this.handleScroll);
    }

    stopAutoSave();
  }

  setMissingQuestion = question => {
    this.setState({ missingQuestion: question });
    if (question == null) {
      this.missingQuestionRef = null;
    }
  };

  scrollToMissingQuestion = () => {
    if (this.missingQuestionRef != null) {
      const index = this.listRefs.indexOf(this.missingQuestionRef);
      // find index of the previous question
      const indexPrevious = index > 0 ? index - 1 : -1;

      if (indexPrevious !== -1) {
        this.scrollToNext(this.listRefs[indexPrevious]);
      }
    }
  };

  getCenterOfPreview(headerHeight) {
    const { scrollBody } = this.props;
    if (scrollBody || this.preview == null) {
      return (windowHeight + headerHeight) / 2;
    }

    return (getHeight(this.preview) + headerHeight) / 2;
  }

  handleScroll = event => {
    event.preventDefault();
    const { surveyStarted } = this.props.status;
    if (surveyStarted) {
      const scrollTop = this.props.scrollBody
        ? getScrollTop()
        : getScrollTop(event.target);
      const previewHeader = document.querySelector('#preview-header');
      const headerHeight = previewHeader != null ? getHeight(previewHeader) : 0;
      const centerPreview = this.getCenterOfPreview(headerHeight) + scrollTop;
      const firstDimension =
        this.listDimensionsRefs.length > 0 ? this.listDimensionsRefs[0] : null;
      const firstDimensionPos =
        firstDimension != null ? firstDimension.offsetTop : 200;
      const showDimensionPreview = scrollTop > firstDimensionPos - headerHeight;

      if (previewHeader) {
        if (showDimensionPreview) {
          document.querySelector('.box-animation').classList.add('animate');
        } else {
          document.querySelector('.box-animation').classList.remove('animate');
        }
      }

      let centeredElement = null;
      let currentDimensionIndex = -1;
      const elemBeforeCenter = {
        elem: undefined,
        distance: undefined,
        isDimensionHeader: false,
      };
      const elemAfterCenter = {
        elem: undefined,
        distance: undefined,
        isDimensionHeader: false,
      };

      this.listRefs.forEach(elem => {
        if (!isElementInViewport(elem, scrollTop, headerHeight)) {
          return;
        }

        const indexOfDimension = this.listDimensionsRefs.indexOf(elem);
        const distance = isElementInCenterPreview(elem, centerPreview);

        if (indexOfDimension !== -1) {
          // isDimensionWrapper
          if (distance === 0) {
            currentDimensionIndex = indexOfDimension;
          }
          return;
        }

        /**
         * check if the element is a dimension description or title (isn't a question)
         */
        const isDimensionHeader =
          elem.classList.contains('dim__wrapper') ||
          elem.classList.contains('dim__title__wrapper');

        // the element is below the center of page
        if (distance > 0) {
          if (
            elemAfterCenter.elem == null ||
            Math.abs(distance) < Math.abs(elemAfterCenter.distance)
          ) {
            elemAfterCenter.elem = elem;
            elemAfterCenter.distance = distance;
            elemAfterCenter.isDimensionHeader = isDimensionHeader;
          }
        } else if (distance < 0) {
          // the element is above the center of page
          if (
            elemBeforeCenter.elem == null ||
            distance < elemBeforeCenter.distance
          ) {
            elemBeforeCenter.elem = elem;
            elemBeforeCenter.distance = Math.abs(distance);
            elemBeforeCenter.isDimensionHeader = isDimensionHeader;
          }
        }

        if (distance === 0) {
          // the element is in the center of page
          centeredElement = elem;

          if (isDimensionHeader) {
            // isDimensionHeader
            currentDimensionIndex = indexOfDimension;
            this.changeDimensionOpacity(elem, SHOW);
          } else {
            this.changeQuestionOpacity(elem, SHOW);
          }
        } else if (isDimensionHeader) {
          // distance != 0 && isDimensionHeader
          this.changeDimensionOpacity(elem, HIDE);
        } else {
          // distance != 0 && !isDimensionHeader
          this.changeQuestionOpacity(elem, HIDE);
        }
      });

      if (centeredElement == null) {
        if (elemBeforeCenter.elem != null && elemAfterCenter.elem != null) {
          centeredElement =
            elemBeforeCenter.distance < elemAfterCenter.distance
              ? elemBeforeCenter
              : elemAfterCenter;
        } else if (elemBeforeCenter.elem) {
          centeredElement = elemBeforeCenter;
        } else {
          centeredElement = elemAfterCenter;
        }

        if (centeredElement.elem && centeredElement.isDimensionHeader) {
          this.changeDimensionOpacity(centeredElement.elem, SHOW);
        } else if (centeredElement.elem) {
          this.changeQuestionOpacity(centeredElement.elem, SHOW);
        }
      }

      if (
        currentDimensionIndex !== this.currentDimensionIndex &&
        currentDimensionIndex !== -1
      ) {
        const profiling = this.props.survey?.dimensions?.find(
          ({ isProfile }) => isProfile,
        );
        /**
         * this function used also to set the current Dimension
         * we set the current dimension in order to update the text in Preview Header
         */
        this.props.setCurrentQuestion(
          currentDimensionIndex + (profiling ? 1 : 0),
          -1,
        );
        this.currentDimensionIndex = currentDimensionIndex;
      }
    }
  };

  changeQuestionOpacity = (questionContainer, action = SHOW) => {
    const navigationButtons = questionContainer.querySelector(
      '.qst__navigation',
    );

    if (action === HIDE) {
      questionContainer.style.opacity = HIDDEN_OPACITY;
      questionContainer.style.boxShadow = 'none';
      navigationButtons.style.display = 'none';
      return;
    }

    questionContainer.style.opacity = 1;
    questionContainer.style.boxShadow = '0 4px 4px rgba(41,57,77,.1)';
    navigationButtons.style.display = 'block';
  };

  changeDimensionOpacity = (dimensionContainer, action = SHOW) => {
    const dimensionDescription = dimensionContainer.querySelector(
      '.dim__description',
    );

    if (action === HIDE) {
      dimensionContainer.style.opacity = HIDDEN_OPACITY;
      if (dimensionDescription) {
        dimensionDescription.style.boxShadow = 'none';
      }
      return;
    }

    dimensionContainer.style.opacity = 1;
    if (dimensionDescription) {
      dimensionDescription.style.boxShadow = '0 4px 4px rgba(41,57,77,.1)';
    }
  };

  scrollToTop() {
    if (this.props.scrollBody) {
      scrollTo(0, ANIMATION_DURATION);
      return;
    }

    if (this.preview) {
      scrollTo(0, ANIMATION_DURATION, this.preview, doScrollElement);
    }
  }

  addRef(ref, position = null, question = null) {
    if (!this.listRefs.includes(ref) && ref != null) {
      const { length } = this.listRefs;
      if (position != null) {
        this.listDimensionsRefs.push(ref);
        this.listRefs.splice(length - position, 0, ref);
        return;
      }
      this.listRefs.push(ref);
    }
    if (
      question != null &&
      this.state.missingQuestion != null &&
      question.id === this.state.missingQuestion.id
    ) {
      this.missingQuestionRef = ref;
    }
  }

  removeRef(ref) {
    this.listDimensionsRefs = this.listDimensionsRefs.filter(
      item => item !== ref,
    );
    this.listRefs = this.listRefs.filter(item => item !== ref);
  }

  resetListRefs() {
    this.listRefs = [];
    this.listDimensionsRefs = [];
  }

  /**
   * @param {boolean} goBack if true scroll to previous question
   */
  scrollToNext(currentElement, goBack = false) {
    // eslint-disable-line consistent-return
    const indexElement = this.listRefs.indexOf(currentElement);
    const previewHeader = document.querySelector('.preview__ctrlPanel--top');
    const headerHeight = previewHeader != null ? getHeight(previewHeader) : 0;
    const isLastElement = indexElement >= this.listRefs.length - 1;
    const isOutOfBound = goBack
      ? indexElement < 1
      : indexElement == -1 || isLastElement;

    if (!isOutOfBound) {
      const nextElementIndex = goBack ? indexElement - 1 : indexElement + 1;
      const element = this.listRefs[nextElementIndex];
      const isDimension = this.listDimensionsRefs.indexOf(element) !== -1;

      if (isDimension) {
        return this.scrollToNext(element, goBack);
      }

      const topPos = element.offsetTop;
      const previewHeight = this.props.scrollBody
        ? windowHeight
        : getHeight(this.preview);
      const centerPreview = this.getCenterOfPreview(headerHeight);
      const elementHeight = getHeight(element);
      let pos = 0;
      if (elementHeight > previewHeight - headerHeight) {
        pos = topPos - headerHeight;
      } else {
        pos = topPos - centerPreview + elementHeight / 2;
      }

      // hack to fix scroll to position zero if the question is hidden
      if (topPos === 0) return this.scrollToNext(element, goBack);

      if (this.props.scrollBody) {
        scrollTo(pos, ANIMATION_DURATION);
        return null;
      }

      if (this.preview) {
        scrollTo(pos, ANIMATION_DURATION, this.preview, doScrollElement);
      }
    } else if (isLastElement && !goBack) {
      const submitButton = document.querySelector('#submit-response');

      if (submitButton) {
        submitButton.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }
  }

  addDimensionOverlayRef(ref) {
    this.dimensionOverlay = ref;
  }

  renderBanner = () => {
    const {
      language,
      survey: { banner },
    } = this.props;
    return (
      banner &&
      banner[language] && (
        <div className="preview__container no-padding">
          <div className="preview__container__title--image">
            <img
              style={{ width: '100%' }}
              alt=""
              src={`${TTP_API_URL}/${banner[language]}`}
            />
          </div>
        </div>
      )
    );
  };

  renderSurvey() {
    const { surveyStarted, surveyEnded } = this.props.status;
    const {
      fakeResponse,
      survey,
      organization,
      client,
      readOnly,
      readOnlyMsg,
      lng,
      surveyResponseStatus,
      auth,
      language,
      surveyResponseFetching,
    } = this.props;
    // let isClient = false;
    // const hasFullAccess = loggedAs == 'CHIEF_EDITOR' && this.hasClientAuthorization();

    // if (auth.user !== null) {
    //   __.forEach(auth.user.roles, (item) => {
    //     const { owner } = item.organization;
    //     if (owner != null && owner.id != null) {
    //       isClient = true;
    //     }
    //   });
    // }

    if (!surveyStarted) {
      return (
        <SurveyTitlePreview
          title={survey.title}
          description={survey.description}
          setMissingQuestion={this.setMissingQuestion}
          fakeResponse={fakeResponse}
          scrollToTop={this.scrollToTop}
          readOnly={readOnly}
          organization={organization}
          client={client}
          survey={survey}
        />
      );
    }

    /** !surveyEnded to show the ending message */
    if (!fakeResponse && !surveyEnded) {
      if (surveyResponseStatus == 'COMPLETED' && !survey.allowEditResponse) {
        return (
          <PreviewMessage title={_('user_has_already_responded_title')}>
            {_('user_has_already_responded_message')}
          </PreviewMessage>
        );
      }
    }
    // TODO
    // if (!fakeResponse && !hasFullAccess) {
    //   if (!isClient && survey.type != 'OPINION') {
    //     return (
    //       <PreviewMessage title={_('user_not_client_of_organization_title')}>
    //         {_('user_not_client_of_organization_message')}
    //       </PreviewMessage>
    //     );
    //   }
    // }

    const question = getAllQuestionsOfSurvey(survey);
    const nbQuestions = question.filter(({ parent }) => parent == null).length;
    /**
     * cast id to string because ids in store.surveyResponse.answers stored as strings (id is the keys of answers)
     */
    const childQuestions = question
      .filter(({ parent }) => parent != null)
      .map(({ id }) => String(id));

    const profiling = survey.dimensions.find(({ isProfile }) => isProfile);
    const dimensions = survey.dimensions.filter(({ isProfile }) => !isProfile);

    return (
      <Fragment>
        <div
          className="preview__ctrlPanel preview__ctrlPanel--top"
          id="preview-header"
        >
          <div className={`box-animation ${surveyEnded ? 'no-animate' : ''}`}>
            <PreviewHeader title={survey.title} lng={lng} />
            <TopBar nbQuestions={nbQuestions} childQuestions={childQuestions} />
          </div>
        </div>
        {surveyEnded ? null : (
          <>
            {profiling && !surveyResponseFetching && (
              <Profiling
                hide={survey.meta?.hideProfiling == true}
                user={auth.user}
                profiling={profiling}
                language={language}
                isAnonymousSurvey={survey.isAnonymous}
              />
            )}
            <DimensionPreviewList
              fakeResponse={fakeResponse}
              dimensions={dimensions}
              key={shortId.generate()}
              addRef={this.addRef}
              scrollToNext={this.scrollToNext}
              addDimensionOverlayRef={this.addDimensionOverlayRef}
              removeRef={this.removeRef}
              surveyStatus={survey.status}
              missingQuestion={this.state.missingQuestion}
              scrollToMissingQuestion={this.scrollToMissingQuestion}
              resetListRefs={this.resetListRefs}
              readOnly={readOnly}
              readOnlyMsg={readOnlyMsg}
            />
          </>
        )}
        <SubmitResponse
          readOnly={readOnly}
          fakeResponse={fakeResponse}
          readOnlyMsg={readOnlyMsg}
          surveyEnded={surveyEnded}
          isPublicStats={survey.isPublicStats}
        />
      </Fragment>
    );
  }

  render() {
    return (
      <div className="preview__wrapper row" id="scrollPreview">
        <div className="preview__background survey-main-background" />
        <div className="preview__background--bottom" />
        {this.renderSurvey()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  language: state.surveys.list.language,
  lng: state.params.lng,
  loggedAs: state.auth.loggedAs,
  surveyResponseStatus: state.surveyResponse.surveyResponse?.status,
  surveyResponseFetching: state.surveyResponse.fetching,
});

const mapDispatchToProps = { setCurrentQuestion };

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
