import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { completeSurvey, setCurrentQuestion } from 'actions';
import { persistSurveyResponse, subscribeToStats } from 'thunks';
import { connect } from 'react-redux';
import __ from 'lodash';
import _ from 'i18n';
import { getHeight, doScrollBody } from 'scroll';
import { getAllQuestionsOfSurvey } from 'question';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import TTButton from '../common/TTButton';
import DisplayErrors from './DisplayErrors';

export class SubmitResponse extends Component {
  state = {
    processingType: -1,
    hide: false,
    isAnswerInvalid: false,
    isMissingAnswer: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { hide } = this.state;
    const { subscribedToStats, surveyEnded } = this.props;

    if (
      prevProps.subscribedToStats != subscribedToStats ||
      prevState.hide != hide ||
      prevProps.surveyEnded != surveyEnded
    ) {
      const submitResponse = document.querySelector('#submit-response');

      if (submitResponse) {
        const previewHeader = document.querySelector('#preview-header');
        const headerHeight =
          previewHeader != null ? getHeight(previewHeader) : 0;
        const srh = getHeight(submitResponse);
        submitResponse.style.bottom = `calc(50vh - ${(srh + headerHeight) /
          2}px)`;

        setTimeout(() => doScrollBody(0), 0);
      }
    }
  }

  onClick = () => {
    const { answers, surveyResponse, fakeResponse, readOnly } = this.props;
    const { id, survey, userParticipant } = surveyResponse;

    if (this.validateSurveyResponse() && !fakeResponse && !readOnly) {
      if ((survey != null && userParticipant != null) || id) {
        this.props.persistSurveyResponse(surveyResponse, answers).then(
          () => {
            this.props.completeSurvey(true);
          },
          err => {
            const status = err.response ? err.response.status : null;
            const message =
              err.response.data && err.response.data.errors
                ? _(err.response.data.errors.message)
                : '';
            if (status == 400 && message == 'answers_already_submitted') {
              this.props.completeSurvey(true);
            }
          },
        );
      } else {
        this.props.completeSurvey(true);
      }
    } else if (fakeResponse) {
      this.props.completeSurvey(true);
    }
  };

  validateAnswers() {
    const { error } = this.props;
    const errorAnswers = error != null ? error.answers || {} : {};
    const isValid =
      Object.keys(errorAnswers).length === 0 &&
      errorAnswers.constructor === Object;

    this.setState({ isAnswerInvalid: !isValid });
    return isValid;
  }

  validateRequiredQuestion() {
    const { answers, survey } = this.props;
    const requiredQuestionsId = getAllQuestionsOfSurvey(survey)
      .filter(({ required }) => required)
      .map(({ id, uid }) => id ?? uid);
    const answersIds = __.map(answers, answer => answer.question);

    for (const id of requiredQuestionsId) {
      if (answersIds.indexOf(id) === -1) {
        this.setState({ isMissingAnswer: true });
        return false;
      }
    }

    this.setState({ isMissingAnswer: false });
    return true;
  }

  validateSurveyResponse() {
    return this.validateRequiredQuestion() && this.validateAnswers();
  }

  handleSubscribe = subscribe => {
    const {
      surveyResponse: { subscribedToStats },
      fakeResponse,
    } = this.props;

    if (this.state.processingType != -1) {
      return;
    }

    if (fakeResponse || subscribedToStats == subscribe) {
      this.setState({ hide: true }); // remove when activating redirect to home
      return;
    }

    this.setState({ processingType: subscribe ? 1 : 0 });

    this.props.subscribeToStats(subscribe).then(() => {
      this.setState({ processingType: -1, hide: true });
    });
  };

  render() {
    const {
      saving,
      readOnly,
      readOnlyMsg,
      surveyEnded,
      isPublicStats,
    } = this.props;
    const {
      processingType,
      hide,
      isAnswerInvalid,
      isMissingAnswer,
    } = this.state;
    const { subscribedToStats } = this.props.surveyResponse;
    const disabled = readOnly;

    return (
      <div className="preview__wrapper__item small-11">
        {(isAnswerInvalid || isMissingAnswer) && (
          <div className="preview__container qst__wrapper no-margin--top">
            <div className="preview__container__content qst__content">
              {isAnswerInvalid && (
                <DisplayErrors message={_('answers_not_valid_message')} />
              )}
              {isMissingAnswer && (
                <DisplayErrors message={_('required_answers_message')} />
              )}
            </div>
          </div>
        )}
        <div
          className={`preview__container submit-response survey-main-content-background-color ${
            surveyEnded ? 'ended' : ''
          }`}
          id="submit-response"
        >
          {!surveyEnded ? (
            <div className="text-center send-wrapper">
              <TTButton
                text={saving ? `${_('Processing')} ...` : _('Submit')}
                className="full p-x-xxl"
                onClick={saving ? null : this.onClick}
                fontSize="20px"
                color="green"
                upperCase={true}
                dataTip={true}
                dataFor="submit-response"
                disabled={disabled}
              />
              {readOnly && (
                <ReactTooltip
                  id="submit-response"
                  multiline={true}
                  className="react-tooltip"
                  effect="solid"
                >
                  <span className="tooltip-content">{readOnlyMsg}</span>
                </ReactTooltip>
              )}
            </div>
          ) : (
            <>
              <div className="submited-success">
                <div className="text-center title">{_('THANK YOU !')}</div>
                <div className="text-center sub-title">
                  {_('Thank you for devoting time to this survey.')}
                </div>
              </div>
              {isPublicStats && !subscribedToStats && !hide ? (
                <div className="receive-stats">
                  <div className="message survey-main-color">
                    {_('subscribe_to_stats_msg')}
                  </div>
                  <div className="actions">
                    <button
                      type="button"
                      className="recieve-stats-button survey-main-content-gradient-background-color"
                      onClick={() => this.handleSubscribe(true)}
                    >
                      {processingType === 1
                        ? `${_('Processing')} ...`
                        : _('Yes')}
                    </button>
                    <button
                      type="button"
                      className="recieve-stats-button survey-main-content-gradient-background-color"
                      onClick={() => this.handleSubscribe(false)}
                    >
                      {processingType === 0
                        ? `${_('Processing')} ...`
                        : _('No')}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex-column-center m-t-l">
                  <TTButton
                    text={_('goBackToHome')}
                    className="rounded p-x-s"
                    icon="icon-arrow-left"
                    iconFontSize="16px"
                    onClick={() => this.props.history.push('/')}
                    fontSize="18px"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  answers: state.surveyResponse.answers,
  error: state.surveyResponse.error,
  surveyResponse: state.surveyResponse.surveyResponse,
  saving: state.surveyResponse.saving,
  language: state.surveys.list.language,
  survey: state.surveys.list.survey,
});

const mapDispatchToProps = {
  completeSurvey,
  persistSurveyResponse,
  setCurrentQuestion,
  subscribeToStats,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(SubmitResponse);
