import React, { useEffect } from 'react';
import { fetchDimensionScore } from 'thunks';
import { getTextByLanguage } from 'surveyUtils';
import { NavLink } from 'react-router-dom';
import { RESULTS } from 'constants';
import NotFoundPage from 'NotFoundPage';
import controlAccess from 'stats/controlAccess';
import DimensionResultsFetching from 'fetching/stats/results/DimensionResultsFetching';
import shortId from 'shortid';

const DimensionResults = (props) => {
  const {
    items,
    lng,
    organization,
    fetching,
    error,
    hide,
    disabled,
    survey: { slug, latestInstance },
    surveySlug,
    fetchingSurvey
  } = props;

  useEffect(() => {
    if (slug && surveySlug == slug) {
      props.fetchDimensionScore(latestInstance?.id ?? 0, organization);
    }
  }, [surveySlug, slug]);

  const renderCard = () => items.map((item) => {
    const dimensionId = item.dimension && item.dimension.id;
    const goTo = {
      pathname: location.pathname,
      search: `?tab=${`${RESULTS}-${dimensionId}`}&dimension=${dimensionId}&organization=${organization}`
    };

    return (
      <div className="column column-block" key={dimensionId || shortId.generate()}>
        <NavLink to={goTo} activeClassName="active">
          <div className="result-card">
            <h2 className="card-title">{item.dimension && getTextByLanguage(item.dimension.title, lng)}</h2>
            <div className="card-score">
              <div className="score-item">
                <div className="score-label">
                  <label>NPS</label>
                </div>
                <div className="score-score">{item.nps ? `${item.nps} %` : '--'}</div>
              </div>
              <div className="score-item">
                <div className="score-label"><label>SCORE</label></div>
                <div className="score-score">{item.score ? `${item.score} %` : '--'}</div>
              </div>
            </div>
          </div>
        </NavLink>
      </div>
    );
  });

  if (fetching || fetchingSurvey) {
    return <DimensionResultsFetching />;
  }

  if (hide || disabled) {
    return <NotFoundPage mainClass="small-10" />;
  }

  if ((error != null && error.code == 404) || !items || items.length == 0) {
    return <NotFoundPage mainClass="small-10" />;
  }

  return (
    <div className="row wrapper">
      <div className="medium-12 columns">
        <div className="row small-up-1 medium-up-2 large-up-3">
          {renderCard()}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  items: state.dimensionScore.items,
  fetching: state.dimensionScore.fetching,
  fetchingSurvey: state.surveys.list.fetching,
  error: state.dimensionScore.error,
  lng: state.params.lng
});

const mapDispatchToProps = { fetchDimensionScore };

export default controlAccess(mapStateToProps, mapDispatchToProps)(DimensionResults);
