import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  initSurvey, completeSurvey, startSurvey,
} from 'actions';
import { bindActionCreators } from 'redux';
import Preview from 'Preview';
import SurveyStyle from '../preview-items/SurveyStyle';

class EditorPreview extends Component {
  resetSurvey() {
    this.props.initSurvey();
    this.props.startSurvey(false);
    this.props.completeSurvey(false);
  }

  render() {
    return (
      <div className={`preview column srv-background-color--fill ${this.props.fullWidth ? 'medium-12' : 'medium-6'}`}>
        {/* <div className="clearfix preview__resetWrapper">
          <div className="preview__resetWrapper__reset float-right" onClick={() => this.resetSurvey()}>
            <span>{_('Reset')}</span>
          </div>
        </div> */}
        <SurveyStyle theme={this.props.survey.meta?.theme} />
        <Preview
          survey={this.props.survey}
          status={this.props.status}
          fakeResponse={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  survey: state.surveys.list.survey,
  theme: state.surveyThemes.theme,
  status: state.surveyResponse.status,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ initSurvey, completeSurvey, startSurvey }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorPreview);
