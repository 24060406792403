import React, { Component } from 'react';
import { connect } from 'react-redux';
import __ from 'lodash';
import _ from 'i18n';
import { generateQuestion, isListQuestion } from 'surveyUtils';
import { QUESTIONS } from 'SurveyConfig';
import { getLinkedToOptions } from 'question';
import { addQuestion, setCurrentQuestion } from 'actions';
import { OPEN_QUESTIONS } from 'Common';
import TTPSelect from 'common/TTPSelect';

class LinkedQuestionMaker extends Component {
  constructor(props) {
    super(props);
    const { parentQuestion, surveyType, surveySteps, language } = props;

    const options = getLinkedToOptions(
      parentQuestion,
      surveyType,
      surveySteps,
      language,
    );

    this.state = {
      options,
      linkedValue: options?.[0]?.value,
    };
  }

  componentDidUpdate(prevProps) {
    const { parentQuestion, surveyType, surveySteps, language } = this.props;

    if (
      prevProps.parentQuestion !== parentQuestion ||
      prevProps.surveyType !== surveyType ||
      prevProps.surveySteps !== surveySteps ||
      prevProps.language !== language
    ) {
      const { linkedValue } = this.state;
      const options = getLinkedToOptions(
        parentQuestion,
        surveyType,
        surveySteps,
        language,
      );

      this.setState({
        linkedValue: options?.find(({ value }) => value == linkedValue) ? linkedValue : options?.[0]?.value,
        options,
      });
    }
  }

  handleClick = type => {
    const { parentQuestion, orderDimension, order, surveyType } = this.props;
    const { linkedValue } = this.state;
    const { id, uid } = parentQuestion;
    const question = generateQuestion(type, surveyType);

    if (isListQuestion(parentQuestion)) {
      question.condition = {
        choice: linkedValue,
        resourceType: 'choice',
      };
    } else {
      question.condition = {
        value: linkedValue,
        resourceType: 'value',
      };
    }
    question.parent = parseInt(id) || uid;
    this.props.setCurrentQuestion(orderDimension || 0, order || 0);
    this.props.addQuestion(question);
    this.props.onClose();
  };

  render() {
    const { surveyType } = this.props;
    const { options: questionOptions, linkedValue } = this.state;
    const options =
      questionOptions?.map(({ label, value }) => ({ label, value })) ?? [];

    return (
      <>
        <div className="add-block__header">
          {_('Required previous answer is')}:
        </div>
        <div className="add-block__content">
          <TTPSelect
            simple={true}
            notClearable={true}
            values={String(linkedValue)}
            options={options}
            onChange={linkedValue => this.setState({ linkedValue })}
          />
        </div>
        <div className="add-block__header">{_('Choose a question')}</div>
        <div className="add-block__blocks">
          {__.map(QUESTIONS, ({ key, name, color, icon }) => {
            let disabled = false;
            if (surveyType == 'SATISFACTION') {
              if (OPEN_QUESTIONS.indexOf(key) !== -1) {
                disabled = true;
              }
            }
            const disabledClass = (disabled && 'disabled-link') || '';

            return (
              <div
                className={`add-block__block_item ${disabledClass}`}
                onClick={!disabled && this.handleClick.bind(this, key)}
                key={key}
              >
                <div className="block-icon" style={{ backgroundColor: color }}>
                  <span>
                    <i className={icon} />
                  </span>
                </div>
                <div className="block-title">{name}</div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  surveySteps: state.surveys.list.survey.steps,
  surveyType: state.surveys.list.survey.type,
  language: state.surveys.list.language,
});

const mapDispatchToProps = { addQuestion, setCurrentQuestion };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LinkedQuestionMaker);
