import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateAnswer, addLastUpdatedAnswer } from 'actions';
import { deleteAnswer } from 'thunks';
import { isDisplayed } from 'surveyUtils';
import { selectAnswerByQuestion } from 'reducers';
import QuestionChoiceItem from './QuestionChoiceItem';
import PreviewControlButton from './PreviewControlButton';
import { QuestionHeader } from './QuestionHeader';
import ChoiceInput from './ChoiceInput';

class MultipleChoiceQuestionPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      answers: props.answer ? new Set(props.answer.choice) : new Set(),
      otherValue: props.answer ? props.answer.otherValue : null,
      answerValidated: true,
    };
    this.state.otherSelected =
      this.state.otherValue && this.state.otherValue.length != 0;

    this.onChange = this.onChange.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.handleOtherChecked = this.handleOtherChecked.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { parentAnswer, question } = this.props;

    if (prevProps.answer != this.props.answer) {
      this.setState({
        answers: this.props.answer
          ? new Set(this.props.answer.choice)
          : new Set(),
        otherValue: this.props.answer?.otherValue ?? null,
      });
    } else if (
      isDisplayed(question, parentAnswer) !==
      isDisplayed(question, prevProps.parentAnswer)
    ) { 
      this.setState({
        answers: new Set(),
        otherValue: null,
      });
    }
  }

  onChange = (value, isSelected) => {
    const { type } = this.props.question;
    if (type === 'MULTIPLE_CHOICE') {
      const { answers } = this.state;

      if (isSelected) {
        answers.add(value);
      } else {
        answers.delete(value);
      }
      this.setState({ answers, answerValidated: false }, this.updateAnswer);
    } else if (type === 'RATING_CHOICE' || type === 'SINGLE_CHOICE') {
      const answers = new Set();
      if (isSelected) {
        answers.add(value);
      }
      this.setState(
        {
          answers,
          otherValue: null,
          otherSelected: false,
          answerValidated: true,
        },
        () => {
          this.updateAnswer();
          if (this.state.answers?.size > 0) {
            setTimeout(() => {
              this.props.scrollToNext(this.ref);
            }, 0);
          }
        },
      );
    }
  };

  onTextChange = text => {
    const { type } = this.props.question;
    if (type === 'SINGLE_CHOICE') {
      this.setState(
        {
          answers: new Set(),
          otherValue: text,
          otherSelected: text != '',
          answerValidated: false,
        },
        () => {
          this.updateAnswer();
        },
      );
    }
  };

  onClick = goBack => {
    this.props.scrollToNext(this.ref, goBack);
    this.setState({ answerValidated: true });
  };

  updateAnswer() {
    const {
      updateAnswer,
      question,
      addLastUpdatedAnswer,
      deleteAnswer,
    } = this.props;
    const { answers, otherValue } = this.state;

    const answer = {
      ...this.props.answer,
      question: question.id || question.uid,
      choice: answers.size !== 0 ? [...answers] : null,
      otherValue,
      resourceType: 'multipleChoice',
    };

    updateAnswer(answer);

    if (
      answer.choice != null ||
      (answer.otherValue && answer.otherValue.length != 0)
    ) {
      addLastUpdatedAnswer(question.id || question.uid);
    } else if (answer.id) {
      deleteAnswer(answer.id, question.id);
    }
  }

  handleOtherChecked(value) {
    if (value) {
      this.setState(prevState => ({
        ...prevState,
        answers: new Set(),
        otherSelected: true,
      }));
    } else {
      this.setState(
        prevState => ({
          ...prevState,
          otherValue: '',
          otherSelected: false,
        }),
        () => {
          this.updateAnswer();
        },
      );
    }
  }

  renderListChoices() {
    const { answers, otherSelected } = this.state;
    const { question } = this.props;
    let choices = [];

    if (Array.isArray(question.choices)) {
      choices = question.choices.map((choice, key) => {
        const value = choice.id || choice.uid;
        const selected = answers.has(value);
        const label = choice.text ? choice.text[this.props.language] : '';
        return (
          <QuestionChoiceItem
            selected={selected}
            id={`choice-label-${value}`}
            key={value}
            order={key}
            label={label}
            value={value}
            onChange={this.onChange}
            onBlur={this.onBlur}
          />
        );
      });
    }

    if (question.hasOther && question.hasOther != '0') {
      const label = question.otherLabel
        ? question.otherLabel[this.props.language]
        : '';

      choices.push(
        <ChoiceInput
          selected={otherSelected}
          key={`other=${question.id || question.uid}`}
          order={choices.length}
          label={label}
          value={this.state.otherValue}
          onChange={this.onTextChange}
          alwaysDisplayLabel={question.isOtherOverwritten == '0'}
          handleOtherChecked={this.handleOtherChecked}
          onEnter={this.onClick}
        />,
      );
    }

    return choices;
  }

  render() {
    const { question, language, parentAnswer, order } = this.props;
    const { answers, answerValidated } = this.state;
    const { text } = question;
    const isShowed = isDisplayed(question, parentAnswer);
    const hasParent = question.parent != null;
    const showValidateButton =
      (answers.size != 0 && question.type === 'MULTIPLE_CHOICE') ||
      (question.type == 'SINGLE_CHOICE' &&
        this.state.otherValue &&
        this.state.otherValue != '');

    return (
      <div
        className="preview__container qst__wrapper"
        style={{ display: isShowed ? 'block' : 'none' }}
        ref={ref => {
          this.ref = ref;
          this.props.addRef(ref, null, question);
        }}
      >
        {/* <div className="preview__container__bg" /> */}
        <QuestionHeader
          id={`question-label-${question.id || question.uid}`}
          text={text[language]}
          order={order}
          isRequired={question.required == 1}
          hasOrder={!hasParent}
        />
        <div className="preview__container__content qst__content">
          <div className="block-choices">
            <div className="choices-column">{this.renderListChoices()}</div>
          </div>
          <PreviewControlButton
            className="srv-button-color--fill"
            onClick={this.onClick}
            show={showValidateButton && !answerValidated}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { question } = props;
  const questionId = question.id || question.uid;

  return {
    language: state.surveys.list.language,
    answer: state.surveyResponse.answers.hasOwnProperty(questionId)
      ? state.surveyResponse.answers[questionId]
      : null,
    parentAnswer: selectAnswerByQuestion(question.parent)(state.surveyResponse),
  };
};

const mapDispatchToProps = { updateAnswer, addLastUpdatedAnswer, deleteAnswer };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultipleChoiceQuestionPreview);
