import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateAnswer, addLastUpdatedAnswer } from 'actions';
import { deleteAnswer } from 'thunks';
import { bindActionCreators } from 'redux';
import _ from 'i18n';
import { isDisplayed } from 'surveyUtils';
import { selectAnswerByQuestion } from 'reducers';
import DisplayErrors from './DisplayErrors';
import PreviewControlButton from './PreviewControlButton';
import { QuestionHeader } from './QuestionHeader';


class NumberQuestionPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answerValue: props.answer ? props.answer.answerValue : '',
      isAnswerValid: true,
      answerValidated: true
    };

    this.onClick = this.onClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { parentAnswer, question } = this.props;

    if (
      isDisplayed(question, parentAnswer) !==
      isDisplayed(question, prevProps.parentAnswer)
    ) {
      this.setState({ answerValue: '', isAnswerValid: true, answerValidated: true });
    }
  }

  onChange(number) {
    this.setState({
      answerValue: number,
      isAnswerValid: true,
      answerValidated: false
    });

    if (number == '') this.onClick();
  }

  onKeyDown = (e) => {
    if (e.key == 'Enter') {
      e.preventDefault();
      this.onClick({ withScroll: true });
    }
  };

  onClick = ({ withScroll, goBack } = {}) => {
    const { question } = this.props;
    const { answerValue } = this.state;
    const isAnswerValid = this.validateAnswer();
    if (isAnswerValid || answerValue == '') {
      const answer = {
        ...this.props.answer,
        question: question.id || question.uid,
        answerValue,
        resourceType: 'number'
      };

      this.setState({ isAnswerValid: true });
      this.props.updateAnswer(answer);

      if (answer.answerValue != null && answer.answerValue.length !== 0) {
        this.props.addLastUpdatedAnswer(question.id || question.uid);
      } else if (answer.id) {
        this.props.deleteAnswer(answer.id, question.id);
      }

      if (withScroll) {
        setTimeout(() => {
          this.props.scrollToNext(this.ref, goBack);
        }, 0);
        this.setState({ answerValidated: true });
      }
    } else {
      this.setState({ isAnswerValid: false });
    }
  }

  goToNext = (goBack) => {
    if (goBack || this.state.answerValue == '' || this.validateAnswer()) {
      this.props.scrollToNext(this.ref, goBack);
      this.setState({ answerValidated: true });
    }
  }

  getErrorMessage() {
    const { question } = this.props;
    if (question.minNumber != null && question.maxNumber == null) {
      return `${_('min_number_error')} ${question.minNumber}`;
    }
    if (question.maxNumber != null && question.minNumber == null) {
      return `${_('max_number_error')} ${question.maxNumber}`;
    }

    return `${_('between_number_error')} ${question.minNumber} ${_('and')} ${question.maxNumber}`;
  }

  validateAnswer() {
    const { question } = this.props;
    const { answerValue } = this.state;
    let isValid = true;
    let optionsExist = (question.minNumber != null);
    if (optionsExist) {
      isValid = isValid && (Number(answerValue) >= Number(question.minNumber));
    }
    optionsExist = (question.maxNumber != null);
    if (optionsExist) {
      isValid = isValid && (Number(answerValue) <= Number(question.maxNumber));
    }
    return isValid;
  }

  render() {
    const {
      question, language, parentAnswer, order
    } = this.props;
    const { answerValue, isAnswerValid, answerValidated } = this.state;
    const { text } = question;
    const isShowed = isDisplayed(question, parentAnswer);
    const hasParent = question.parent != null;

    return (
      <div className="preview__container qst__wrapper" style={{ display: isShowed ? 'block' : 'none' }} ref={(ref) => { this.ref = ref; this.props.addRef(ref, null, question); }}>
        {/* <div className="preview__container__bg" /> */}
        <QuestionHeader
          id={`question-label-${question.id || question.uid}`}
          text={text[language]}
          order={order}
          isRequired={question.required == '1'}
          hasOrder={!hasParent}
        />
        <div className="preview__container__content qst__content">
          <div className="preview-input">
            <input
              type="number"
              className="transparent-input srv-secondary-color srv-secondary-color "
              onKeyDown={this.onKeyDown.bind(this)}
              onChange={e => this.onChange(e.target.value)}
              onBlur={this.onClick}
              placeholder={_('Answer_input')}
              value={answerValue}
            />
          </div>
          {
            !isAnswerValid
            && <DisplayErrors message={this.getErrorMessage()} />
          }
          <PreviewControlButton className="srv-button-color--fill" show={answerValue != '' && !answerValidated} onClick={this.goToNext} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { question } = props;
  const questionId = question.id || question.uid;

  return {
    language: state.surveys.list.language,
    answer: state.surveyResponse.answers.hasOwnProperty(questionId) ? state.surveyResponse.answers[questionId] : null,
    parentAnswer: selectAnswerByQuestion(question.parent)(state.surveyResponse),
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateAnswer, addLastUpdatedAnswer, deleteAnswer }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberQuestionPreview);
