export default {
  home: 'Tamtam Survey',
  signIn: 'Connectez-vous / Inscrivez-vous',
  'About us': 'À propos de nous',
  owrApps: 'Nos applications',
  pageNotFound: "Oups, cette page n'a pas pu être trouvée!",
  goBackToHome: "Retournez à la page d'accueil",
  logout: 'se Déconnecter',
  help: 'Aide',
  Communities: 'Communautés',
  campaigns: 'Campagnes',
  createCampaign: 'Créer une campagne',
  webPage: 'Page web',
  previous: 'Précédent',
  next: 'Suivant',
  filter: 'Filtre',
  status: 'Statut',
  DRAFT: 'BROUILLON',
  SENT: 'ENVOYÉE',
  CREATED: 'CRÉÉ',
  language: 'Langue',
  reset: 'Réinitialiser',
  searchBySubject: 'Recherche par sujet',
  subject: 'Sujet',
  cancel: 'ANNULER',
  save: 'ENREGISTRER',
  codeOwn: 'Rédigez la vôtre',
  createdAt: 'Créé le',
  updatedAt: 'Modifé le',
  endedAt: 'Terminé le',
  startedAt: 'Commencé le',
  groups: 'Groupes',
  sent: 'Envoi',
  enterText: 'Entrez un texte ...',
  deleteCampaignConfirm: 'Voulez-vous vraiment supprimer la campagne?',
  delete: 'supprimer',
  fromEmail: "De l'email",
  fromName: 'Du nom',
  replyTo: 'RÉPONDRE Á',
  recipientType: 'Type de destinataire',
  typeTestEmail:
    'Tapez les adresses mail auquelles vous souhaitez envoyer le test',
  settings: 'paramètres',
  warning: 'warning',
  groupsIncluded: 'GROUPES INCLUS',
  excludedGroups: 'GROUPES EXCLUS',
  selectGroups: 'Sélectionner des groupes',
  excludeGroups: 'Exclure des groupes',
  'Select event': 'Sélectionnez un événement',
  'Select activities': 'Sélectionnez les activités',
  'Exclude activities': 'Exclure les activités',
  'Select status': 'Sélectionnez les statuts',
  'Exclude status': 'Exclure les statuts',
  sendTitle: "Vous êtes sur le point d'envoyer une campagne à",
  tested: 'Testé',
  attachments: 'Pièces jointes',
  confirm: 'CONFIRMER',
  confirmSendCampaign: 'Êtes-vous sûr de vouloir envoyer cette campagne?',
  noNotNow: 'NON, PAS MAINTENANT',
  yesSend: 'OUI, ENVOYER',
  sendTestTo: 'Envoyer le test à',
  sendTest: 'ENVOYER LE TEST',
  send: 'envoyer',
  PLAIN_TEXT: 'TEXT',
  WEB_PAGE: 'PAGE WEB',
  selectTarget: 'Sélectionnez un public cible',
  campaignInfo: 'Informations sur la campagne',
  invalidObject:
    "l'objet doit comporter au moins 5 caractères et pas plus de 200 caractères",
  invalidFromName: 'du nom invalide, veuillez entrer plus de 4 caractères',
  invalidEmail: 'email adresse invalide',
  emailAdd: 'Ajoutez des emails et tappez ENTER',
  userAdd: 'Ajoutez un utilisateur et tappez ENTER',
  deleteCampaignSuccess: 'Suppression reussie',
  campaignDeleted: 'La campagne a était supprimée avec succès',
  campaignTestSent: 'Un email de test a été envoyé avec succès',
  campaignTestSuccess: 'Test réussi',
  campaignSent: 'La campagne a été envoyée avec succès',
  campaignSentSuccess: 'Envoi reussi',
  campaignAdded: 'La campagne a était enregistrée avec succès',
  addCampaignSuccess: 'Ajout réussi',
  successfulAction: 'Action réussie',
  operationCompleted: 'Action réalisée avec succès',
  errorOccured: 'Une erreur est survenue, essayez plus tard.',
  incompleteOperation:
    "Impossible de terminer l'opération, des données incorrectes ont été soumises",
  invalidUrl:
    "Impossible de terminer l'opération, un url invalide a été soumis",
  error: 'erreur',
  enterUrl: "Entrer l'URL",
  search: 'Recherche',
  deliveredTo: 'Livré à',
  emailsOpen: 'Emails ouverts',
  linkClicks: 'Liens cliqués',
  Bounce: '',
  Spam: '',
  recipientsMessage: 'ce filre prend en considération la langue :',
  pauseCampaign:
    "pause de la campagne empêche l'envoi <br>d'autres email jusqu'à ce qu'elle soit réactivée",
  pauseSending: "pause l'envoi d'autre email",
  stopCampaign: "arrêter définitivement l'envoi de la campagne",
  resume: 'reprendre',
  register_from_email:
    "Cette adresse n'est pas valide pour l'envoi via Amazon, cliquez sur VALIDER pour lancer le processus d'enregistrement",
  reply_aws_email:
    "Un email a été envoyé par Amazon à l'adresse email Répondez-y et rafraichissez !",
  replyToError:
    'Veuillez entrer un email valide ou bien un text dans le format : nom <email@example.com>',
  validate: 'valider',
  refresh: 'rafraîchir',
  GUEST: 'INVITÉ',
  SPEAKER: 'ORATEUR',
  CONTACT: '',
  PROSPECT: '',
  PARTNER: 'PARTENAIRE',
  templateChoices: 'Veuillez choisir une template',
  'first name': 'prenom',
  'last name': 'nom',
  stopCampaignConfirm:
    'Voulez-vous vraiment arrêter définitivement cette campagne?',
  stop: 'ARRÊTER',
  Event: 'Evénement',
  'Included activities': 'Activités incluses',
  'Excluded activities': 'Activités exclues',
  'Included status': 'Status incluses',
  'Excluded status': 'Status exclues',
  Formulas: 'Formules',
  Slots: '',
  Options: '',
  All: 'TOUT',
  templates: 'Modèles',
  NEXT: 'SUIVANT',
  nothing_found: 'Oops, Aucun résultat ne correspond à votre recherche',
  yes: 'oui',
  no: 'non',
  french: 'français',
  english: 'anglais',
  dutch: 'néerlandais',
  overview: 'aperçu',
  links: 'liens',
  recipients: 'destinataires',
  approvals: 'approbations',
  approval_preferences: "Les préférences d'approbation d'une campagne emailing",
  'send me': "m'envoyer",
  'send to approvers': 'envoyer aux approbateurs',
  'Send your campaign immediately': 'Envoyez votre campagne immédiatement',
  'Schedule sending of your campaign for a specific time':
    "Programmer l'envoi de votre campagne pour une heure précise",
  'the approvers': 'les approbateurs',
  'the test is obligatory': 'le test est obligatoir',
  'automatic sending': "l'envoi automatique",
  'the minimum number of acceptance': "le nombre minimum d'acceptation",
  automatic_sending_paragraph:
    "permettre l’envoi automatique de la campagne lorsqu'on atteint le nombre limite sans aucun rejet",
  test_obligatory_paragraph:
    "la campagne ne sera pas envoyée avant l'envoi d'un test",
  minimum_acceptance_paragraph:
    "le nombre minimum de validation est requis pour l'envoi automatique",
  no_approver_found: "vous n'avez pas encore défini les approbateurs",
  'Add an approver': 'Ajoutez un approbateur',
  'delivery date': "date et heure d'envoi",
  'Drag and drop some files here, or click to select files to upload.':
    'Faites glisser et déposez certains fichiers ici, ou cliquez pour sélectionner les fichiers à télécharger.',
  Attachments: 'Pièces jointes',
  attachment: 'pièce jointe',
  reason: 'raison',
  'Thank you for approving this campaign':
    "Merci d'avoir approuvé cette campagne",
  'your acceptance is taken into consideration':
    'votre acceptation est prise en considération',
  'please specify the reason for rejection':
    'merci de spécifier la raison du rejet',
  about_us_content:
    "est une suite d'outils qui permet à une communauté de communiquer, partager, organiser ou  collaborer efficacement.  La plateforme est équipée de diverses applications assemblées selon les besoins par le client.  Grace  à nos outils mutualisés, nos clients disposent des dernières technologies sans devoir en assurer le développement au profit leur core business.",
  'No approval request is sent for this release':
    "Aucune demande d'approbation n'est envoyé pour cette version",
  "approvers' feedback for the test version":
    'les retours des approbateurs pour la version de test',
  upload: 'télécharger',
  apply: 'appliquer',
  close: 'fermer',
  'Once you close your changes will be lost. Do you really want to continue':
    'Vos modifications seront perdues. Voulez-vous vraiment continuer',
  'search by title': 'recherche par titre',
  none: 'aucun',
  'last modified on': 'dernière modification le',
  by: 'par',
  replicate: 'reproduire',
  original: 'original',
  replication: 'réplication',
  'Apply authorization to selected users':
    "Appliquer l'autorisation aux utilisateurs sélectionnés",
  Apply: 'Appliquer',
  'Add new user': 'Ajouter un nouvel utilisateur',
  'Select All': 'Tout sélectionner',
  'Deselect All': 'Déselectionner',
  'allow deletion': 'permettre la suppression',
  'allow change': 'permettre le changement',
  'allow create campaign': 'Autoriser la création de la campagne',
  'allow send campaign': "Autoriser l'envoi de la campagne",
  'Are you sure you want to delete this template':
    'Êtes-vous sûr de vouloir supprimer ce modèle',
  'Allow use for all users':
    "Autoriser l'utilisation pour tous les utilisateurs",
  'Allow use for certain users':
    "Autoriser l'utilisation pour certains utilisateurs",
  'user preferences': 'les préférences utilisateurs',
  'Add an image for this template': 'Ajouter une image pour ce modèle',
  privilege: 'privilège',
  lists: 'listes',
  'create list': 'créer une liste',
  'search by name': 'rechercher par nom',
  subscribers: 'abonnés',
  'mandatory list': 'liste obligatoire',
  name: 'nom',
  Preview: 'Aperçu',
  Duplicate: 'Dupliquer',
  Publish: 'Publier',
  Results: 'Résultats',
  Delete: 'Supprimer',
  Dashboard: 'Tableau de bord',
  Status: 'Statut',
  remove_block_message: 'Voulez-vous vraiment supprimer ce champ',
  save_survey_success: 'Le questionnaire a été Enregistré avec succès',
  close_survey_message: 'Voulez-vous vraiment clôturer ce questionnaire',
  close_survey_success: 'Le questionnaire a été clôturé avec succès',
  delete_survey_message: 'Voulez-vous vraiment supprimer ce questionnaire',
  delete_survey_success: 'Le questionnaire a été supprimé avec succès',
  delete_question_message: 'Voulez-vous vraiment supprimer cette question',
  delete_question_success: 'La question a été supprimé avec succès',
  delete_dimension_message: 'Voulez-vous vraiment supprimer cette dimension',
  delete_dimension_success: 'La dimension a été supprimé avec succès',
  delete_choice_message: 'Voulez-vous vraiment supprimer ce choix?',
  delete_choice_success: 'Le choix a été supprimé avec succès',
  delete_survey_theme_message: 'Voulez-vous vraiment supprimer ce thème?',
  delete_survey_theme_success: 'Le thème a été supprimé avec succès',
  survey_closed_message:
    'Merci pour votre temps, malheureusement cette enquête a été clôturée / terminée',
  survey_not_started_message:
    "Merci pour votre temps, malheureusement cette enquête n'est pas encore disponible",
  close_survey: 'Clôturer le questionnaire',
  language_not_available:
    "Votre langue n'est pas encore prise en charge. Veuillez sélectionner une langue dans la liste suivante",
  Close: 'Fermer',
  'New survey': 'Nouvelle enquête',
  'My WorkSpace': 'Mon Espace de Travail',
  Surveys: 'Enquêtes',
  Description: 'Description',
  Dimension: 'Dimension',
  'Text Input': 'Saisie de text',
  'Multiple selection': 'Sélection Multiple',
  'Single Selection': 'Sélection Unique',
  'Number Input': 'Entrée de Nombre',
  Legal: 'Légal',
  'Yes/No': 'Oui/Non',
  Rating: 'Évaluation',
  'Opinion scale': "Échelle d'opinion",
  Question_input: 'Saisir votre question ici ...',
  Save: 'Enregistrer',
  preview: 'aperçu',
  Title: 'Titre',
  Survey_title: 'Titre du Questionnaire',
  ENTER: 'ENTRER',
  'I accept': "J'accepte",
  "I don't accept": "Je n'accepte pas",
  Yes: 'Oui',
  No: 'Non',
  press: 'Ou cliquez sur',
  min_number_error: 'Veuillez entrer un nombre supérieur à',
  max_number_error: 'Veuillez entrer un nombre inférieur à',
  between_number_error: 'Veuillez entrer un nombre entre',
  length_invalid_message:
    'Cette valeur est trop courte. Elle devrait avoir 2 caractères ou plus.',
  length_3_invalid_message:
    'Cette valeur est trop courte. Elle devrait avoir 3 caractères ou plus.',
  invalid_answer_message: 'Réponse non valide',
  required_answers_message: 'Veuillez remplir les champs obligatoire',
  answers_not_valid_message: 'veuillez corriger les champs en surbrillance',
  and: 'et',
  Answer_input: 'Saisir votre réponse ici',
  'Thanks for completing this survey !': "Merci d'avoir répondu à ce sondage",
  Start: 'Début',
  'to make a line break': 'pour faire un saut de ligne',
  Submit: 'valider',
  Required: 'Champs Obligatoires',
  'Vertical Alignment': 'Alignement Vertical',
  'Min Number': 'Nombre Min',
  'Max Number': 'Nombre Max',
  Shape: 'Forme',
  Steps: 'Pas',
  searchByOrganizationName: "Recherche par nom d'organsiation",
  ResultsByOrganization: 'Résultas par fiduciaire',
  'Filter by': 'Filtrer par',
  'Ranking Fiduciaries': 'Classement des Fiduciaires',
  'Ranking Clients of Organization': 'Classement des clients de fiduciaire',
  'Ranking Clients': 'Classement des Clients',
  "My Organization's ranking": 'Ma Fiduciare',
  'Languages score': 'Score de langues',
  'Translation status': 'Statut de traduction',
  'Survey status': "Statut de l'enquête",
  'out of': 'sur',
  'people answered this question': 'personnes ont répondu à cette question',
  'Total number of responses': 'Nombre totale de réponse',
  'Nobody answered this question yet':
    "Personne n'a encore répondu à cette question",
  responses: 'réponses',
  response: 'réponse',
  edit_penetration_rate: 'Le taux de pénétration a été modifié avec succès',
  Edit: 'Editer',
  'Set Penetration Rate': 'Modifier le taux de pénétration',
  Settings: 'Paramètres',
  Survey_Template: 'ENQUÊTES TEMPLATE',
  create_survey: 'Créer le questionnaire',
  create_template: 'Générer une template',
  create_template_from_survey: 'Intégrer la bibliothèque',
  create_survey_from_template_success:
    'Le questionnaire de ce modèle a été creé avec succès',
  'Save as template': 'Enregistrer le modèle',
  "Tamtam's surveys": 'Les enquêtes de Tamtam',
  'My surveys': 'Mes Enquêtes',
  duplicate_survey_success: "L'enquête a été dupliquée avec succès",
  user_has_already_responded_title: 'Vous avez déjà répondu à ce questionnaire',
  user_has_already_responded_message:
    "Vous ne pouvez remplir ce formulaire qu'une seule fois. Essayez de contacter le propriétaire du formulaire si vous pensez que c'est une erreur.",
  user_not_client_of_organization_title:
    "Vous n'êtes pas un client d'une fiduciare",
  user_not_client_of_organization_message:
    "Ce questionnaire est adressé au client des fiduciares vous n'êtes pas concerné par cette enquête. Essayez de contacter le propriétaire du formulaire si vous pensez que c'est une erreur.",
  Back: 'Retour',
  'Template State': 'Etat du Modèle',
  Saved: 'Enregistré',
  Validate: 'Valider',
  'Score by Dimension': 'Score par Dimension',
  'Satisfaction Template': 'Modèle de Satisfaction',
  'Custom Template': 'Modèle Personnalisé',
  'Building a Survey': 'Construire un Questionnaire',
  Describe_survey_tamtam_app:
    "TamTamSurvey est le moyen le plus simple de créer des enquêtes et d'obtenir des réponses. TamTamSurvey vous permet de créer rapidement une enquête et d'obtenir des réponses ciblées du public auquel vous souhaitez poser des questions.",
  'Not just a Pretty interface': 'Pas seulement une jolie interface',
  'Impress your audience with our plan':
    'Impressionnez votre public avec notre plan',
  'A gorgeous, smooth interface that keeps people engaged.':
    'Une interface magnifique et lisse qui maintient les gens engagés.',
  'See every change you make in real-time with Live Preview.':
    'Visualisez chaque modification que vous apportez en temps réel avec Live Preview.',
  'Logic Jump makes each form smarter and more personal.':
    'Logic Jump rend chaque formulaire plus intelligent et plus personnel.',
  ACCOMPLISHED: 'ACCOMPLIE',
  'IN PROGRESS': 'EN COURS',
  'linked to': 'lié à',
  'Add a sub question': 'Ajouter une sous question',
  'Add question': 'Ajouter une question',
  'Required previous answer is': 'Réponse précédente requise est',
  'Choose a question': 'Choisissez une question',
  Profiling: 'Profilage',
  not_valid_email: "Cette adresse email n'est pas valide",
  'Please enter your Email': 'Veuillez saisir votre Email',
  popup_survey_status_message: "Changer le statut de l'enquête",
  edit_survey_status: "Le statut de l'enquête a été modifié avec succès",
  srv_start: 'Continue',
  'Profiling Filters': 'Filtres de profilage',
  'search value': 'valeur de recherche',
  Language: 'Langue',
  ' people answered by this option': 'personnes ont répondu par cette option',
  'Response Summary': "Résultats de l'enquête",
  Processing: 'En traitement',
  msg_template_not_exist: "Ce Type de Modèle n 'est pas encore diponible",
  'Please refresh this page': 'Veuillez actualiser cette page',
  'Results are being prepared': 'Les résultats sont en préparation',
  opinionSurvey: "Enquête d'opinion",
  satisfactionSurvey: 'Enquête mesurée',
  customSurvey: 'Enquête personnalisée',
  created: 'créé',
  PENDING: 'EN ATTENTE',
  STARTED: 'COMMENCÉ',
  ENDED: 'TERMINÉ',
  CLOSED: 'CLOTURÉ',
  SATISFACTION: 'SATISFACTION',
  OPINION: 'OPINION',
  CUSTOM: 'PERSONNALISÉ',
  private: 'privé',
  public: 'publique',
  owner: 'propriétaire',
  environment: 'environnement',
  'Updated At': 'Modifié le',
  nbResponses: 'Nombre de réponses',
  'Template gallery': 'Galerie de modèles',
  template_picker_infos:
    'Choisissez un modèle ou cliquez sur partir de zéro pour créer une nouvelle enquête.',
  'Start from scratch': 'Partir de zéro',
  'Use this template': 'Utilisez ce modèle',
  'write yours': 'écrivez le vôtre',
  'create from template': 'créer à partir de modèle',
  'Edit answer': 'Modifier la réponse',
  Participate: 'Participer',
  cancel_participate: 'Annuler la participation',
  'Already answered': 'Déja repondu',
  CHIEF_EDITOR: 'Rédacteur en chef',
  AUTHOR: 'Auteur',
  REDACTOR: 'Rédacteur',
  LEGAL_REPRESENTATIVE: 'REPRÉSENTANT LÉGAL',
  MANAGER: 'GÉRANT',
  createSurvey: "créé l'enquête",
  editableResponse: 'La réponse est modifiable',
  no_survey_data_msg: 'Aucun article correspondant trouvé',
  no_survey_data_info: 'Vérifiez les paramètres du filtre',
  all: 'tous',
  penetration_rate: 'Taux de pénétration',
  isTemplate: 'Enquête template',
  isSectorial: 'Enquête consolidée',
  isFixed: 'Enquête Non personnalisée',
  obligatoryRanking: 'Classement obligatoire',
  consolidatedResults: 'Résultats consolidés',
  'Consolidated survey': 'Enquête consolidée',
  participation_msg_success: 'Vous avez participé avec succès',
  cancel_participate_msg_success: 'La participation a été annulée avec succès',
  cancel_participate_msg: 'Voulez-vous vraiment annuler votre participation?',
  participate_msg: 'Êtes-vous sûr de vouloir participer à cette enquête?',
  participate_opinion_msg:
    'Acceptez-vous le partage des résultats de votre organization?',
  participate_satisfaction_msg:
    'Acceptez-vous le classement de votre organization?',
  create_template_modal_msg: 'Choisissez une opération',
  'Transform into a consolidated survey': 'Transformer en enquête consolidée',
  transform_to_sectorial_template_success: 'La template a été crée avec succès',
  "You can't send answer in a template":
    'Vous ne pouvez pas envoyer de réponse dans une template',
  'Add welcome screen': 'Ajouter un écran de bienvenue',
  'Add banner': 'Ajouter une bannière',
  Add: 'Ajouter',
  Update: 'Mettre à jour',
  Library: 'Bibliothèque',
  Organization: 'Organisation',
  duplicate_into_library: 'Dupliquer dans la bibliothèque',
  themes: 'thèmes',
  Editor: 'Éditeur',
  design: 'désign',
  'Public themes': 'Thèmes publics',
  'My themes': 'Mes thèmes',
  Customize: 'Personnaliser',
  'Create new Theme': 'Créer un nouveau thème',
  'Welcome message': 'Message de bienvenue',
  'Rating choice': 'Choix de classement',
  choice: 'choix',
  'Survey description': "Description de l'enquête",
  Revert: 'Revenir',
  Ranking: 'Classement',
  Responses: 'Réponses',
  stats_question_no_data_found_msg:
    'Si vous avez soif de réponses, faites-en une question obligatoire dans les paramètres de la question.',
  stats_survey_no_data_found_msg: "Cette enquête n'a pas encore de réponses.",
  'No signs of movement': 'Aucun signe de mouvement',
  'No campaign was created yet!': "Aucune campagne n'a encore été créée",
  Targets: 'Cibles',
  Object: 'Objet',
  'Campaign stats': 'Stats de la campagne',
  'Ranking client': 'Classement des clients',
  'Ranking organizations': 'Classement des organisations',
  Participants: 'Participants',
  'My organization': 'Mon organisation',
  SURVEY_INVITATION: "Modèle d'invitation",
  SURVEY_REMINDER: 'Modèle de rappel',
  SURVEY_PARTIAL_REMINDER: 'Modèle de rappel partiel',
  SURVEY_THANK: 'Modèle de remerciement',
  SURVEY_RESULTS: 'Modèle de résultats',
  'Emails Templates': 'Emails modèles',
  'Customize your email Templates': "Personnaliser vos modèles d'emails",
  subscribe_to_stats_msg:
    "Voulez-vous recevoir les statistiques dès qu'elles seront disponibles ?",
  other: 'autre',
  'Public stats': 'Stats publiques',
  'Copied to clipboard': 'Copié dans le presse-papier',
  Invited: 'Invité',
  Revival: 'Relance',
  revival: 'relance',
  PartialRevival: 'Relance partielle',
  Participated: 'Participé',
  Thanks: 'Remerciement',
  STATUS_RESULTS_NOT_ASKED: 'Non demandé',
  STATUS_RESULTS_RECEIVED: 'Reçu',
  STATUS_RESULTS_ASKED: 'Demandé',
  Uninvited: 'Non invité',
  Received: 'Reçu',
  'Not received': 'Non réçu',
  'Not participated': 'Non participé',
  Manage: 'Gérer',
  'Complete responses': 'Réponses Complètes',
  'Incomplete responses': 'Réponses incomplètes',
  guests: 'invités',
  results: 'résultats',
  'Suggested campaigns': 'Campagnes suggérées',
  'Show all results': 'Afficher tous les résultats',
  'Delete dimension': 'Supprimer la dimension',
  'Delete question': 'Supprimer la question',
  'Delete choice': 'Supprimer le choix',
  Languages: 'Langues',
  'no template is available in this language':
    "aucun modèle n'est disponible dans cette langue",
  Templates: 'Modèles',
  'Mandatory acknowledgment mail': 'Mail de remerciement obligatoire',
  'Send Test emails to': 'Envoyer des emails de test à',
  'Apply a template': 'Appliquer un modèle',
  Community: 'Communauté',
  from_email_not_registred:
    "Cette adresse n'est pas valide pour l'envoi via Amazon",
  'Language unavailable': 'Langue indisponible',
  'Emails templates': "Template d'emails",
  'Integrate the English language': 'Intégrer la langue anglais',
  'Integrate the French language': 'Intégrer la langue française',
  'Integrate the Dutch language': 'Intégrer la langue néerlandaise',
  'Edit combination': 'Modifier la combinaison',
  'Duplicate the model': 'Dupliquer le modèle',
  'Initialize the mails': 'Initialiser les mails',
  'Results not asked': 'Résultats non demandés',
  'User has already received results':
    "L'utilisateur a déjà reçu les résultats",
  'Missing generic emailing of language':
    'Emailing générique manquant de la langue',
  'User has already completed the survey':
    "L'utilisateur a déjà terminé l'enquête",
  'The maximum number of reminders has been exceeded':
    'Le nombre maximum de rappels a été dépassé',
  'User was invited at': "L'utilisateur a été invité le",
  'you can send reminder after': 'vous pouvez envoyer un rappel après',
  'User already received a reminder at':
    "L'utilisateur a déjà reçu un rappel le",
  years: 'ans',
  months: 'mois',
  days: 'jours',
  hours: 'heures',
  minutes: 'minutes',
  seconds: 'secondes',
  'a year': 'une année',
  'a month': 'un mois',
  'a day': 'un jour',
  'an hour': 'une heure',
  'a minute': 'une minute',
  'a few seconds': 'quelques secondes',
  'Send results email': 'Envoyer un email de résultats',
  'Send a reminder email': 'Envoyer un email de relance',
  'Send a partial reminder email': 'Envoyer un email de relance partiel',
  Emails: 'E-mails',
  'Notifications of updates': 'Notifications des mises à jour',
  'Nothing to show': 'Rien à afficher',
  'TAMTAM applications': 'Applications TAMTAM',
  cannot_delete_answered_question:
    "Vous ne pouvez pas supprimer les questions qu'ont été répondues.",
  'Initial stats': 'Stats initiales',
  complete: 'complètes',
  incomplete: 'incomplètes',
  'New campaign': 'Nouvelle campagne',
  'Revival has already been sent': 'La relance a déjà été envoyé',
  'The reminders sent': 'Les relances envoyés',
  create: 'créer',
  answers: 'résponses',
  'Please Choose An Available Language':
    'Veuillez choisir une langue disponible',
  'Log in': 'Identifiez-vous',
  'Please enter your email address': 'Veuillez saisir votre adresse email',
  'Already have an account': 'Vous avez déjà un compte',
  email: 'mail',
  Continue: 'Continuez',
  'well done': 'bravo',
  'you have completed your survey': 'vous avez complété votre sondage',
  'Do you want to receive statistics ?':
    'Voulez vous recevoir les statistiques ?',
  'Create your custom satisfaction survey.':
    'Créez votre enquête de satisfaction personnalisée.',
  'Create your custom poll survey.': 'Créez votre sondage personnalisé.',
  'Create your custom quiz survey.': 'Créez votre quiz personnalisé.',
  'Join with the Event Mobile app':
    "Rejoignez-vous avec l'application Event Mobile",
  'with Game PIN': 'avec le PIN de jeu',
  Players: 'Joueurs',
  Play: 'Jouer',
  'Game PIN': 'PIN de jeu',
  exit: 'quitter',
  'Top ten': 'Top dix',
  'please configure quiz display': "veuillez configurer l'affichage du quiz",
  'select the display type': "sélectionner le type d'affichage",
  'select the languages to display': 'sélectionner les langues à afficher',
  bilingual: 'bilingue',
  monolingual: 'monolingue',
  'Select a game': 'Sélectionnez un jeu',
  'Please select a game': 'Veuillez sélectionner un jeu',
  'Add "Other" option': 'Ajouter l\'option "Autre"',
  time: 'temps',
  'Show game PIN to public': 'Afficher le code PIN du jeu au public',
  'Show PIN': 'Afficher le code PIN',
  'Portal question': 'Question de Portail',
  'Create your custom portal question.':
    'Créez votre question de portail personnalisée.',
  'Start date': 'Date de début',
  'End date': 'Date de fin',
  COLLABORATORS: 'COLLABORATEURS',
  CLIENTS: 'CLIENTS',
  ALL: 'TOUT',
  EXTRA_CROSS: 'Extra Cross',
  INTRA_CROSS: 'Intra Cross',
  EXTRA_SHARE: 'Extra Share',
  INTRA_SHARE: 'Intra Share',
  'CREATE, MANAGE AND SEND YOUR SURVEYS':
    'CRÉEZ, GÉREZ ET ENVOYEZ VOS ENQUÊTES',
  Remove: 'Retirer',
  'My Library': 'Ma bibliothèque',
  'Public link': 'Lien publique',
  'Start at': 'De',
  'End at': 'A',
  'Shared survey': 'Enquête partagée',
  'Clients satisfaction': 'Satisfaction des clients',
  'publish in': 'publier en',
  campaign: 'campagne',
  'Collaborators satisfaction': 'Satisfaction des collaborateurs',
  'Confirm delete': 'Confirmation de la suppression',
  'Change survey status to': "Changez le statut de l'enquête en",
  'Hide profiling if known': 'Masquer le profilage si connu',
  'Please fill in this field': 'Veuillez remplir ce champ',
  'Participation of non-members': 'Participation des non-membres',
  'Anonymous survey': 'Enquête anonyme',
  'Hidden name': 'Nom caché',
  members_only_message: 'Cette enquête est accessible aux membres seulement',
  anonymous_message:
    'Vous allez participer à une enquête dont l’anonymat est absolument garanti. L’adresse mail a pour unique but de garantir l’unicité de réponse (1 réponse par participant). Il n’est en aucun cas possible d’identifier qui a répondu quoi.',
  anonymous_thanks_message: 'Déjà merci pour votre participation !',
  'Anonymous User': 'Utilisateur anonyme',
  profiling_anonymous_message:
    'Cette enquête est anonyme. Le profilage déclaré n’est utilisé qu’à titre statistique',
  Profession: 'Profession',
  Age: 'Âge',
  'Postal code': 'Code postal',
  Country: 'Pays',
  'Do you really want to leave this page':
    'Voulez-vous vraiment quitter cette page',
  'Confirm action': "Confirmer l'action",
  View: 'Vue',
  'THANK YOU !': 'MERCI !',
  'Thank you for devoting time to this survey.':
    'Merci pour le temps que vous avez consacré à cette enquête.',
  'Did you save all the changes?':
    'Avez-vous bien enregistrer toutes les modifications ?',
  'Sub-questions': 'Sous-questions',
  INVITATION_CAMPAIGN: 'INVITATION',
  REVIVAL_CAMPAIGN: 'RELANCE',
  PARTIAL_REVIVAL_CAMPAIGN: 'RAPPEL PARTIEL',
  SURVEY_RESULTS_CAMPAIGN: 'RÉSULTAT',
  PARTICIPATIONS_CAMPAIGN: 'PARTICIPATION',
  'Add the non-member to community': 'Ajouter le non-membre à la communauté',
  'I Agree to the terms of service': "J'accepte les conditions d'utilisation",
  'Join the community': 'Rejoindre la communauté',
  'create survey': 'créer une enquête',
  'Easily create, distribute and analyze your surveys!': 'Créez, diffusez et analysez facilement vos enquêtes !',
  'invalid postal code': 'code postal invalide',
};
