import _ from 'i18n';
import { TTP_BACKGROUNDS } from './index';

export const getSurveySettings = type => {
  const commonSettings = ['languages', 'csScope'];

  switch (type) {
    case 'SATISFACTION':
      return [
        ...commonSettings,
        'scope',
        'steps',
        'isSectorial',
        'isFixed',
        'allowEditResponse',
        'allowThirds',
        // 'addThirdsToCommunity',
        'isAnonymous',
        // 'isPublicStats',
        'meta',
      ];
    case 'OPINION':
      return [
        ...commonSettings,
        'isSectorial',
        'isFixed',
        'allowEditResponse',
        'allowThirds',
        // 'addThirdsToCommunity',
        'isAnonymous',
        // 'isPublicStats',
        'meta',
      ];
    case 'PORTAL':
      return [...commonSettings, 'scope', 'dateRange'];
    case 'QUIZ':
      return [...commonSettings];
    default:
      return commonSettings;
  }
};

export const getOptions = type => {
  if (TOOLBAR_ITEMS.hasOwnProperty(type)) {
    return TOOLBAR_ITEMS[type];
  }

  return {
    label: _('Survey_title'),
    icon: 'icomoon-icon icon-embed',
    color: '#7f8080',
    options: {},
  };
};

export const getSurveyQuestion = surveyType => {
  switch (surveyType) {
    case 'PORTAL':
      return ['MULTIPLE_CHOICE', 'SINGLE_CHOICE'];
    case 'QUIZ':
      return ['SINGLE_CHOICE'];
    case 'OPINION':
      return [
        'SINGLE_CHOICE',
        'MULTIPLE_CHOICE',
        'TEXT',
        'RATING',
        'OPINION_SCALE',
        'NUMBER',
        'YES_NO',
        'TRUE_FALSE',
      ];
    case 'SATISFACTION':
      return ['RATING_CHOICE', 'YES_NO', 'RATING', 'OPINION_SCALE'];
    default:
      return [];
  }
};

export const OPTIONS = {
  required: false,
  tips: false,
  isProfile: false,
  timeLimit: 30,
};

/**
 * After updating this object,
 * you must update [OPEN_QUESTIONS] and [QUESTIONS_WITH_CHOICES] in Common.js
 */
export const QUESTIONS = {
  RATING_CHOICE: {
    key: 'RATING_CHOICE',
    name: _('Rating choice'),
    icon: 'icomoon-icon icon-stats-bars',
    color: '#93bae6',
    label: _('Question_input'),
    options: {
      ...OPTIONS,
      verticalAlignment: true,
      isNps: 0,
      hasLinkedQuestions: true,
    },
  },
  YES_NO: {
    key: 'YES_NO',
    name: _('Yes/No'),
    label: _('Question_input'),
    icon: 'icomoon-icon icon-contrast',
    color: '#c1b1f5',
    options: {
      ...OPTIONS,
      hasLinkedQuestions: true,
    },
  },
  RATING: {
    key: 'RATING',
    name: _('Rating'),
    label: _('Question_input'),
    icon: 'icomoon-icon icon-star',
    color: '#ef91af',
    options: {
      ...OPTIONS,
      steps: 5,
      shape: ['star', 'smile', 'checkmark', 'heart'],
      isNps: 0,
      hasLinkedQuestions: true,
    },
  },
  OPINION_SCALE: {
    key: 'OPINION_SCALE',
    name: _('Opinion scale'),
    label: _('Question_input'),
    icon: 'icomoon-icon icon-smile-full',
    color: '#8ab1f8',
    options: {
      ...OPTIONS,
      minNumber: null,
      maxNumber: 10,
      isNps: 0,
      hasLinkedQuestions: true,
    },
  },
  TEXT: {
    key: 'TEXT',
    name: _('Text Input'),
    label: _('Question_input'),
    icon: 'icomoon-icon icon-paragraph-left',
    color: '#f5bb82',
    options: { ...OPTIONS },
  },
  MULTIPLE_CHOICE: {
    key: 'MULTIPLE_CHOICE',
    name: _('Multiple selection'),
    icon: 'icomoon-icon icon-checkbox-checked',
    color: '#81dde6',
    label: _('Question_input'),
    options: {
      type: 'MULTIPLE_CHOICE',
      ...OPTIONS,
      verticalAlignment: true,
      hasLinkedQuestions: true,
    },
  },
  SINGLE_CHOICE: {
    key: 'SINGLE_CHOICE',
    name: _('Single Selection'),
    icon: 'icomoon-icon icon-checkmark-full',
    color: '#cae085',
    label: _('Question_input'),
    options: {
      type: 'SINGLE_CHOICE',
      ...OPTIONS,
      verticalAlignment: true,
      hasLinkedQuestions: true,
      hasOther: false,
      isOtherOverwritten: true,
    },
  },
  NUMBER: {
    key: 'NUMBER',
    name: _('Number Input'),
    label: _('Question_input'),
    icon: 'icomoon-icon icon-plus',
    color: '#f9a298',
    options: {
      ...OPTIONS,
      minNumber: null,
      maxNumber: null,
    },
  },
  TRUE_FALSE: {
    key: 'TRUE_FALSE',
    name: _('Legal'),
    label: _('Question_input'),
    icon: 'icomoon-icon icon-library',
    color: '#e3bc93',
    options: {
      ...OPTIONS,
      hasLinkedQuestions: true,
    },
  },
};

export const TOOLBAR_ITEMS = {
  DESCRIPTION: {
    key: 'DESCRIPTION',
    name: _('Description'),
    label: `${_('Description')} ...`,
    icon: 'icomoon-icon icon-quotes-left',
    color: '#e8d26a',
  },
  DIMENSION: {
    key: 'DIMENSION',
    name: _('Dimension'),
    label: _('Title'),
    icon: 'icomoon-icon icon-stack',
    color: '#74cbe8',
  },
  ...QUESTIONS,
};

export const STATS_SINGLE_COLORS = [
  '#3863A3',
  '#1CAF95',
  '#F3B04E',
  '#E17639',
  '#CDC046',
  '#25B5D5',
];
export const STATS_COLORS = [
  '#3863A3',
  '#1CAF95',
  '#F3B04E',
  '#E17639',
  '#D54141',
  '#B75252',
  '#EA3979',
  '#A356E9',
  '#0091E3',
  '#79B663',
  '#615FD7',
  '#25B5D5',
  '#EF5331',
  '#8D6F34',
  '#258B35',
  '#E13472',
  '#D96868',
  '#A276CB',
  '#CDC046',
  '#BA8446',
];

export const Colors = [
  {
    name: 'blue',
    color: '#2495E1',

    mainColor: '#F3FAFF',
    mainBackgroundColor: '#2495E1',

    mainGradientColor: '#F3FAFF',
    mainBackgroundGradientColor:
      'linear-gradient(180deg, #18A0FB 0%, #2495E1 100%)',
  },
  {
    name: 'red Orange',
    color: '#D5350C',

    mainColor: '#F3FAFF',
    mainBackgroundColor: '#D5350C',

    mainGradientColor: '#F3FAFF',
    mainBackgroundGradientColor:
      'linear-gradient(180deg, #FB3B05 0%, #D5350C 100%)',
  },
  {
    name: 'dark Blue',
    color: '#0B3D84',

    mainColor: '#F3FAFF',
    mainBackgroundColor: '#0B3D84',

    mainGradientColor: '#F3FAFF',
    mainBackgroundGradientColor:
      'linear-gradient(180deg, #0F4EA7 0%, #0B3D84 100%)',
  },
];

export const Themes = [
  {
    name: 'blue',
    background: {
      backgroundImage: `${TTP_BACKGROUNDS}/themes/1.jpg`,
    },
    colors: Colors.find(theme => theme.name === 'blue'),
  },
  {
    name: 'red Orange',
    background: {
      backgroundImage: `${TTP_BACKGROUNDS}/themes/2.jpg`,
    },
    colors: Colors.find(theme => theme.name === 'red Orange'),
  },
  {
    name: 'dark Blue',
    background: {
      backgroundImage: `${TTP_BACKGROUNDS}/themes/3.jpg`,
    },
    colors: Colors.find(theme => theme.name === 'dark Blue'),
  },
];

export const defaultTheme = Themes.find(theme => theme.name === 'blue');

export const backgroundThemes = ['1.jpg', '2.jpg', '3.jpg'];

export const backgroundTextures = ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg'];

export const backgroundColors = [
  'Rouge.jpg',
  'Bleu.jpg',
  'Jaune.jpg',
  'Violet.jpg',
  'Vert.jpg',
];
export const backgroundArchitecture = [
  '1.jpg',
  '2.jpg',
  '3.jpg',
  '4.jpg',
  '5.jpg',
];
export const backgroundNature = ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg'];
