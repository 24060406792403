import React, { Component } from 'react';
import { setCurrentNavPage } from 'actions';
import { connect } from 'react-redux';
import _ from 'i18n';
import * as lottie from 'lottie-web';
import { TTP_AMAZON_S3_PROD } from 'Config';

class HomePage extends Component {
  componentDidMount() {
    this.props.setCurrentNavPage('HOME');
    this.intiAnimation();
  }

  componentWillUnmount() {
    if (this.anim) {
      this.anim.destroy();
    }
  }

  intiAnimation = () => {
    if (this.anim) {
      this.anim.destroy();
    }

    this.anim = lottie.loadAnimation({
      container: this.refs.animationContainer,
      renderer: 'svg',
      loop: 1,
      autoplay: true,
      path: `${TTP_AMAZON_S3_PROD}/survey/assets/animations/survey-header.json`,
    });
  };

  handleClickToPause = () => {
    if (!this.anim) {
      return;
    }
    if (this.anim.isPaused) {
      this.anim.goToAndPlay(0);
    } else {
      this.anim.pause();
    }
  };

  render() {
    return (
      <div className="home-page p-b-xl" id="home-page">
        <div className={'animated-header'}>
          <div className="animated-header__title">
            <h3>{_('Easily create, distribute and analyze your surveys!')}</h3>
          </div>
          <div
            className={'animated-header__animation'}
            ref="animationContainer"
            onClick={this.handleClickToPause}
          />
        </div>
        <div className="row home-page__section">
          <div className="small-12 medium-3 iconbox text-center">
            <img alt="" src="img/home/survey-builder.png" />
          </div>
          <div className="small-12 medium-9 p-x-l content">
            <h4>{_('Building a Survey')}</h4>
            <p>{_('Describe_survey_tamtam_app')}</p>
          </div>
        </div>
        <div className="row home-page__section">
          <div className="small-12 medium-3 iconbox text-center">
            <img alt="" src="img/home/prety-interface.png" />
          </div>
          <div className="small-12 medium-9 p-x-l content">
            <h4>{_('Not just a Pretty interface')}</h4>
            <h6>{_('Impress your audience with our plan')}</h6>
            <ul>
              <li>
                {_('A gorgeous, smooth interface that keeps people engaged.')}
              </li>
              <li>
                {_('See every change you make in real-time with Live Preview.')}
              </li>
              <li>
                {_('Logic Jump makes each form smarter and more personal.')}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { setCurrentNavPage })(HomePage);
