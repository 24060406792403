import React from 'react';
import { APP_ENV, LARAVEL_SESSION_COOKIE } from 'Config';
import { Redirect, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCookie } from 'utils';
import { setAuth, setLanguage, setIsUserVisitor } from 'actions';
import { setCurrentsUser } from 'thunks';
import Cookies from 'universal-cookie';
import { getTTPUser } from 'TamtamAPI';

const RouteWithToken = ({
  auth,
  path,
  component,
  exact,
  renderPropsToComponent
}) => {
  const ComponentToRender = component;
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(setAuth(null));
    const dtExpire = new Date();
    const cookieName = `ttp_auth${APP_ENV != '' ? '_' : ''}${APP_ENV}`;

    dtExpire.setTime(dtExpire.getTime() - 3600000 * 1000);
    setCookie(cookieName, '', dtExpire, '/', 'tamtam.pro');
    setCookie('XSRF-TOKEN', '', dtExpire, '/', 'tamtam.pro');
    setCookie(LARAVEL_SESSION_COOKIE, '', dtExpire, '/', 'tamtam.pro');
  };

  const setAuthCookie = ({ id, firstName, lastName, mainEmail, token }) => {
    // eslint-disable-next-line camelcase
    const { expires, access_token } = token;

    const dtExpire = new Date();
    const cookieName = `ttp_auth${APP_ENV != '' ? '_' : ''}${APP_ENV}`;
    dtExpire.setTime(expires * 1000);

    const authInfos = {
      createdAt: expires - 216000,
      expiresIn: 216000,
      email: mainEmail,
      fullName: `${firstName} ${lastName}`,
      id,
      token: access_token
    };

    const cookies = new Cookies();
    cookies.set(cookieName, authInfos, { expires: dtExpire, path: '/', domain: 'tamtam.pro' });
  };

  const fetchUserDetails = ({ id, token }) => {
    /**
     * No need to dispatch PENDING action here
     * the goal is to fetch the details of user, without addding extra time for the main request
     */
    getTTPUser(id, token?.['access_token']).then((res) => {
      const user = res.data.data[0];
      dispatch(setLanguage(user.language));
      dispatch({ type: 'FETCH_AUTH_USER_FULFILLED', payload: user });
    }, err => err);
  };

  const render = (props) => {
    const componentProps = (renderPropsToComponent && typeof renderPropsToComponent === 'function') ? renderPropsToComponent(props) : props;
    const { organizationInstanceId, slug } = props.match.params;
    const { search } = props.location;
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get('token');

    /** user is not connected or conneted with different [token, user] */
    if ((!auth?.fetched && token != null) || (auth?.fetched && token != null && token != auth?.token)) {
      logout();
      dispatch(setIsUserVisitor(true));
      dispatch(setCurrentsUser(token)).then((resp) => {
        const user = resp.value;
        setAuthCookie(user);
        fetchUserDetails(user);
      });

      return <ComponentToRender {...componentProps} />;
    }

    /** the token is null and the user is not connected */
    if (!auth?.fetched) {
      return (
        <Redirect
          to={{
            pathname: `/anonymous/${slug}/${organizationInstanceId ?? 0}`,
            search,
            state: { from: props.location }
          }}
        />
      );
    }

    /** user already connected and token is not provided in the url */
    if (auth?.fetched && auth?.user) {
      return <ComponentToRender {...componentProps} />;
    }

    return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
  };

  return (
    <Route
      exact={exact}
      path={path}
      render={render}
    />
  );
};

export default RouteWithToken;
