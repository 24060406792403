import * as actions from 'actions';
import * as api from 'TamtamAPI';

export const saveOrganizationInstance = (organizationInstance = {}) => (dispatch, getState) => {
  const { token, currentClient } = getState().auth;

  if (!organizationInstance?.id) {
    organizationInstance = {
      ...organizationInstance,
      organization: currentClient?.id
    };
  }
  return dispatch(actions.saveOrganizationInstance(
    api.saveOrganizationInstance(token, organizationInstance)
  ));
};

export const fetchOrganizationInstance = ({ organizationInstanceId, organizationId, instanceId }) => (dispatch, getState) => {
  const { token } = getState().auth;

  if (organizationInstanceId) {
    return dispatch(actions.fetchOrganizationInstance(
      api.fetchOrganizationInstance(token, organizationInstanceId)
        .then(resp => resp.data.data)
    ));
  }

  return dispatch(actions.fetchOrganizationInstance(
    api.fetchOrganizationInstances(token, organizationId, instanceId)
      .then(resp => resp.data.data?.[0])
  ));
};
