import React, { Component } from 'react';
import __ from 'lodash';
import _ from 'i18n';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  updateQuestion,
  removeQuestion,
  setCurrentQuestion,
  setSettingsCurrentTab,
  addQuestion,
  moveQuestion,
} from 'actions';
import { QUESTION, SURVEY_BUILDER_TABS } from 'constants';
import FloatMenu, { FloatMenuItem, STYLE } from 'common/FloatMenu';
import { deleteQuestion } from 'thunks';
import { QUESTIONS_WITH_CHILDS, QUESTIONS_WITH_CHOICES } from 'Common';
import { getTextByLanguage , generateChoice} from 'surveyUtils';
import { onSuccess, onError } from 'utils';
import { getOptions, getSurveyQuestion } from 'SurveyConfig';
import QuestionChoice from './QuestionChoice';
import QuestionOtherChoice from './QuestionOtherChoice';
import QuestionHeader from './QuestionHeader';
import CreateBlock from './CreateBlock';
import { duplicateQuestion } from 'question';
import MoveButtons from './MoveButtons';
import withHover from 'common/HOC/withHover';

export class QuestionMaker extends Component {
  onOtherLabelChange = text => {
    const { language, updateQuestion, question } = this.props;

    if (text == '' && language != 'fr') {
      const { otherLabel } = question;
      delete otherLabel[language];
      updateQuestion({ ...question, text });
      return;
    }
    updateQuestion({
      ...question,
      otherLabel: { ...question.otherLabel, [language]: text },
    });
  };

  deleteQuestion = () => {
    const {
      question,
      removeQuestion,
      deleteQuestion,
      setCurrentQuestion,
      orderDimension,
      order,
    } = this.props;

    setCurrentQuestion(orderDimension || 0, order || 0);

    if (question.id) {
      deleteQuestion(question.id).then(
        res => onSuccess(res),
        err => onError(err),
      );
    } else {
      try {
        removeQuestion();
      } catch (e) {
        onError();
      }
    }
  };

  handleDuplicateQuestion = () => {
    const { orderDimension, order, question } = this.props;
    this.props.setCurrentQuestion(orderDimension || 0, order || 0);
    this.props.addQuestion(duplicateQuestion(question));
  };

  handleMoveQuestion = direction => {
    const {
      orderDimension,
      order,
      question: { id, uid, type },
    } = this.props;

    this.props.moveQuestion(orderDimension ?? 0, order ?? 0, direction);

    setTimeout(() => {
      const questionDiv = document.querySelector(
        `#question-maker-${id ?? uid}`,
      );

      questionDiv.querySelector('textarea').focus();

      const options = getOptions(type);

      questionDiv.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });

      const pulseDiv = questionDiv?.querySelector('.editor-block__pulse');

      if (!pulseDiv) {
        return;
      }

      if (options) {
        pulseDiv.style.borderColor = options.color;
      }

      document
        .querySelectorAll('.editor-block__pulse.animate')
        .forEach(element => element.classList.remove('animate'));

      window.requestAnimationFrame(time => {
        window.requestAnimationFrame(time => {
          pulseDiv.classList.add('animate');
        });
      });
    }, 0);
  };

  openSettings = () => {
    const { orderDimension, order } = this.props;
    this.props.setCurrentQuestion(orderDimension || 0, order || 0);
    this.props.setSettingsCurrentTab(SURVEY_BUILDER_TABS.SETTINGS);
  };

  renderLine() {
    const { isChild, isBottom, surveyType } = this.props;
    if (isChild && surveyType != 'PORTAL') {
      if (isBottom) {
        return (
          <div className="editor-block__groupLine editor-block__middle editor-block__bottom" />
        );
      }
      return <div className="editor-block__groupLine editor-block__middle" />;
    }
    return null;
  }

  renderChoices() {
    const {
      question,
      orderDimension,
      order,
      isNew,
      focusOnMount,
      language,
      lng,
      isLocked,
      surveyType,
    } = this.props;
    let choices = [];

    if (!QUESTIONS_WITH_CHOICES.includes(question.type)) return null;

    if (__.size(question.choices) !== 0) {
      const disableEditChoice = ['GENDER', 'LANGUAGE'].includes(
        question.profilingType,
      );
      const disableNewChoice =
        ['PORTAL', 'QUIZ'].indexOf(surveyType) !== -1
          ? question.choices.length > 3
          : disableEditChoice;
      choices = question.choices.map((choice, key) => {
        if (choice.choiceOrder !== key) {
          choice.choiceOrder = key;
        }

        return (
          <QuestionChoice
            key={`choice-${choice.id || choice.uid}`}
            order={key}
            orderQuestion={order}
            orderDimension={orderDimension}
            choice={choice}
            hasRank={question.type == 'RATING_CHOICE'}
            focusOnMount={!isNew && focusOnMount}
            isLocked={isLocked && choice.id != null}
            isQuiz={surveyType == 'QUIZ'}
            maxChars={
              ['PORTAL', 'QUIZ'].indexOf(surveyType) !== -1 ? 160 : null
            }
            disableNewChoice={disableNewChoice}
            disabled={disableEditChoice}
            withSettings={question.profilingType === 'AGE'}
          />
        );
      });
    }

    if (__.size(question.choices) === 0) {
      const choice = {...generateChoice(surveyType == 'QUIZ'), choiceOrder: 0 };

      choices.push(
        <QuestionChoice
          key={`choice-${orderDimension}-${order}`}
          order={0}
          choice={choice}
          orderQuestion={order}
          orderDimension={orderDimension}
          focusOnMount={!isNew && focusOnMount}
          isQuiz={surveyType == 'QUIZ'}
          maxChars={['PORTAL', 'QUIZ'].indexOf(surveyType) !== -1 ? 160 : null}
          withSettings={question.profilingType === 'AGE'}
        />,
      );
    }

    if (question.hasOther && question.hasOther == '1') {
      const errors = this.props.errors[question.id || question.uid];
      const { otherLabel } = question;
      const helpText = getTextByLanguage(otherLabel, lng, true);

      choices.push(
        <QuestionOtherChoice
          key={`other-choice-${orderDimension}-${order}`}
          orderQuestion={order}
          orderDimension={orderDimension}
          onTextChange={this.onOtherLabelChange}
          validationParams={{
            resourceType: QUESTION,
            key: question.id || question.uid,
            property: 'otherLabel',
          }}
          value={otherLabel ? otherLabel[this.props.language] : ''}
          errors={errors}
          helpText={helpText}
          focusOnMount={false}
          language={language}
          lng={lng}
          maxChars={['PORTAL'].indexOf(surveyType) !== -1 ? 160 : null}
        />,
      );
    }

    return <div className="choices">{choices}</div>;
  }

  renderMoveButtons() {
    const { question, parentQuestion, firstChild } = this.props;

    const disableUp =
      parentQuestion &&
      (question.questionOrder - parentQuestion.questionOrder <= 1);
    const disableDown =
      firstChild && (firstChild.questionOrder - question.questionOrder <= 1);

    return (
      <div className="block-buttons">
        <MoveButtons
          onClick={this.handleMoveQuestion}
          disableUp={disableUp}
          disableDown={disableDown}
        />
      </div>
    );
  }

  render() {
    const className = `${
      this.props.isChild == true
        ? 'editor-block__container editor-block__child'
        : 'editor-block__container'
    }`;
    const {
      question,
      order,
      orderLabel,
      orderDimension,
      parentLinkedValue,
      focusOnMount,
      style,
      surveyType,
      isLocked,
      focused,
      isBottom: isLastItem,
      onMouseEnter,
      onMouseLeave,
      hovering,
    } = this.props;
    const { id, required, uid, type, parent, profilingType } = question;

    const active = focused || hovering;
    const isProfilage = profilingType && profilingType !== '';
    const acceptChilds =
      !isProfilage &&
      ['SATISFACTION', 'QUIZ', 'PORTAL'].indexOf(surveyType) === -1 &&
      QUESTIONS_WITH_CHILDS.indexOf(type) !== -1 &&
      parent == null;

    return (
      <div
        id={`question-maker-${id ?? uid}`}
        className={className}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="editor-block__root">
          <QuestionHeader
            question={question}
            hasFocus={active}
            required={required == '1'}
            order={order}
            orderLabel={orderLabel}
            orderDimension={orderDimension}
            focusOnMount={focusOnMount}
            isLocked={isLocked}
            disabled={['POSTAL_CODE', 'COUNTRY'].includes(profilingType)}
          />
          {this.renderChoices()}
          {parentLinkedValue && (
            <div className="qst-block-badge">
              <div className="badge-icon">
                <span>
                  <i className="icomoon-icon icon-link" />
                </span>
              </div>
              <div className="badge-content">
                <div className="content-element">{_('linked to')}:</div>
                <div className="content-elemnt greetings">
                  {parentLinkedValue}
                </div>
              </div>
            </div>
          )}
        </div>
        {this.renderLine()}
        <FloatMenu style={STYLE} active={active}>
          <FloatMenuItem
            icon="icomoon-icon icon-right-wrench"
            onClick={this.openSettings}
            id={`float-menu-item-settings-${id ?? uid}`}
          >
            {_('Settings')}
          </FloatMenuItem>
          {!isProfilage && surveyType !== 'PORTAL' && (
            <FloatMenuItem
              icon="icomoon-icon icon-tt-copy"
              onClick={this.handleDuplicateQuestion}
              id={`float-menu-item-duplicate-${id ?? uid}`}
            >
              {_('Duplicate')}
            </FloatMenuItem>
          )}
          <FloatMenuItem
            icon="icomoon-icon icon-bin"
            className="alert-color"
            onClick={this.deleteQuestion}
            disabled={isLocked}
            id={`float-menu-item-delete-${id ?? uid}`}
            message={_('cannot_delete_answered_question')}
          >
            {_('Delete')}
          </FloatMenuItem>
        </FloatMenu>
        <div className={`editor-block__actions ${focused && 'visible'}`}>
          {active && surveyType != 'PORTAL' && !isProfilage && (
            <CreateBlock
              parentQuestion={acceptChilds ? question : null}
              unfocus={onMouseLeave}
              order={order}
              orderDimension={orderDimension}
              blocks={[
                ...(isLastItem ? ['DIMENSION'] : []),
                ...getSurveyQuestion(surveyType),
              ]}
            />
          )}
        </div>
        {active && this.renderMoveButtons()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  language: state.surveys.list.language,
  lng: state.params.lng,
  errors: state.surveys.list.validationErrors.questions.otherLabel,
  surveyType: state.surveys.list.survey.type,
});

const mapDispatchToProps = {
  updateQuestion,
  removeQuestion,
  setCurrentQuestion,
  deleteQuestion,
  setSettingsCurrentTab,
  addQuestion,
  moveQuestion,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHover,
)(QuestionMaker);
