import React from 'react';
import styles from './Profiling.module.scss';
import { getUserNameForAvatar } from 'utils';
import ProfilingForm from './ProfilingForm';
import _ from 'i18n';
import { connect } from 'react-redux';

function Profiling({
  user,
  profiling,
  language,
  hide,
  answers,
  isAnonymousSurvey,
}) {
  const {
    avatarUrl,
    firstName,
    lastName,
    function: userFunction,
    isAnonymous: isAnonymousUser,
  } = user;
  const { title, questions } = profiling;

  let avatarDiv;
  if (isAnonymousUser || isAnonymousSurvey) {
    avatarDiv = (
      <div className={styles.avatar}>
        <img src="/img/icons/anonymous.svg" alt="icon anonyme" />
      </div>
    );
  } else if (avatarUrl) {
    avatarDiv = (
      <div
        className={styles.avatar}
        style={{ backgroundImage: `url(${avatarUrl})` }}
      />
    );
  } else {
    avatarDiv = (
      <div className={styles.avatar}>
        <span>{getUserNameForAvatar(firstName, lastName)}</span>
      </div>
    );
  }

  if (hide) {
    const isAllAnswered = questions.every(({ id }) => {
      const answer = answers?.[id];
      if (!answer) return false;
      else return answer.isAutoFilled;
    });

    if (isAllAnswered) {
      return null;
    }
  }

  const profilingAnswers = questions.reduce((acc, { id, uid }) => {
    const answer = answers?.[id] || answers?.[uid];
    return answer ? { ...acc, [id ?? uid]: answer } : acc;
  }, {});

  return (
    <div className="preview__wrapper__item small-11">
      <div className={`${styles.profiling} preview__container`}>
        <div
          className={`${styles.profile} survey-main-content-background-color`}
        >
          {avatarDiv}
          <div className={styles.profile__infos}>
            <div className={`${styles.text} ${styles.title_text} m-b-xs`}>
              {isAnonymousSurvey
                ? 'Enquête anonyme'
                : isAnonymousUser
                ? _('Anonymous User')
                : `${firstName} ${lastName}`}
            </div>
            <div className={`${styles.text} ${styles.subtitle_text} m-b-xs`}>
              {userFunction}
            </div>
            {isAnonymousSurvey && (
              <div className={styles.info}>
                {_('profiling_anonymous_message')}
              </div>
            )}
          </div>
        </div>
        <div className={styles.profile_details}>
          <div className={`${styles.profile_header} survey-main-color`}>
            <div className={`${styles.title} survey-main-color`}>
              <span>{title[language] ?? _('Profiling')}</span>
            </div>
            {/* <div>Button TODO...</div> */}
          </div>
          <ProfilingForm
            questions={questions}
            language={language}
            answers={profilingAnswers}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  answers: state.surveyResponse.answers,
});

export default connect(mapStateToProps)(Profiling);
