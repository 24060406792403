import React, { Component } from 'react';
import { MENUS } from 'Common';
import { connect } from 'react-redux';
import { setSubMenuDirection } from 'actions';
import { VERTICAL, HORIZONTAL } from 'constants';
import Surveys from './sub-menu/Surveys';
import Template from './sub-menu/Template';
import CreateSurvey from './sub-menu/CreateSurvey';

class SubMenu extends Component {
  handleClick = () => this.props.setSubMenuDirection(this.props.subMenuDirection === VERTICAL ? HORIZONTAL : VERTICAL);

  render() {
    const { auth: { loggedAs, user }, subMenuDirection } = this.props;
    const currentMenus = MENUS[loggedAs];

    if (user == null || user.id == null) {
      return null;
    }

    if (currentMenus.indexOf('SURVEY_LIST') == -1 || currentMenus.indexOf('SURVEY_TEMPLATE') == -1) {
      return null;
    }

    return (
      <nav id="subMenu" className={`sub-menu ${subMenuDirection === VERTICAL ? 'vertical' : ''}`}>
        <span className="flip-menu flex-column-center" onClick={this.handleClick}><i className="icomoon icon-tt-menu" /></span>
        <ul className="menu">
          {currentMenus.indexOf('CREATE_SURVEY') !== -1 ? <CreateSurvey /> : null}
          {currentMenus.indexOf('SURVEY_LIST') !== -1 ? <Surveys /> : null}
          {currentMenus.indexOf('SURVEY_TEMPLATE') !== -1 ? <Template /> : null}
        </ul>
      </nav>
    );
  }
}


const mapStateToProps = state => ({
  auth: state.auth,
  subMenuDirection: state.params.subMenuDirection,
});

const mapDispatchToProps = { setSubMenuDirection };

export default connect(mapStateToProps, mapDispatchToProps)(SubMenu);
