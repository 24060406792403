import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTextByLanguage } from 'surveyUtils';
import ReactTooltip from 'react-tooltip';
import _ from 'i18n';
import { TAMTAM_API_LANGUAGES } from 'Common';
import moment from 'moment';
import ActionsCell from './ActionsCell';
import GroupsCell from './GroupsCell';

class SurveyItem extends Component {
  getColorBorder = status => {
    switch (status) {
      case 'PENDING':
        return 'border-draft';
      case 'STARTED':
        return 'border-success';
      case 'CLOSED':
        return 'border-warning';
      case 'ENDED':
        return 'border-warning';
      default:
        return '';
    }
  };

  getStatus = () => {
    const {
      type,
      startedAt,
      endedAt,
      organizationInstance,
    } = this.props.survey;
    const status = organizationInstance?.status ?? 'PENDING';

    const now = moment();
    let statusPortal = 'PENDING';

    switch (type) {
      case 'PORTAL':
        if (now.isBetween(moment(startedAt), moment(endedAt), null, [])) {
          statusPortal = 'STARTED';
        } else if (now.isAfter(endedAt)) {
          statusPortal = 'ENDED';
        }
        return statusPortal;
      case 'QUIZ':
        return 'STARTED';
      default:
        return status;
    }
  };

  renderStatus = status => {
    const { type, startedAt, endedAt } = this.props.survey;
    const now = moment();
    let statusPortal = 'PENDING';
    const format = 'YYYY-MM-DD H:mm:ss';

    switch (type) {
      case 'PORTAL':
        if (now.isBetween(moment(startedAt), moment(endedAt), null, [])) {
          statusPortal = 'STARTED';
        } else if (now.isAfter(endedAt)) {
          statusPortal = 'ENDED';
        }

        return (
          <span className="survey-status">
            <span className={this.labelStatus(statusPortal)}>
              {_(statusPortal)}
            </span>
            <div className="date-wrapper m-t-xs">
              <span>{_('Start at')}:</span>
              <span>{moment(startedAt).format(format)}</span>
            </div>
            <div className="date-wrapper">
              <span>{_('End at')}:</span>
              <span>{moment(endedAt).format(format)}</span>
            </div>
          </span>
        );
      case 'QUIZ':
        return '--';
      default:
        return <span className={this.labelStatus(status)}>{_(status)}</span>;
    }
  };

  renderIcon() {
    const {
      survey: { id, isSectorial, organization },
      auth,
    } = this.props;

    if (isSectorial) {
      return (
        <>
          <span className="icomoon-icon icon-group-selected sectorial-icon">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
            <span className="path4" />
            <span className="path5" />
            <span className="path6" />
            <span className="path7" />
            <span className="path8" />
            <span className="path9" />
            <span className="path10" />
            <span className="path11" />
            <span className="path12" />
            <span className="path13" />
          </span>
          <ReactTooltip
            id={`sectorial-${id}`}
            multiline={true}
            className="react-tooltip"
          >
            <span className="tooltip-content">{_('Consolidated survey')}</span>
          </ReactTooltip>
        </>
      );
    }

    if (organization && organization?.id != auth.currentClient?.id) {
      return (
        <>
          <span className="icomoon-icon icon-group sectorial-icon" />
          <ReactTooltip
            id={`sectorial-${id}`}
            multiline={true}
            className="react-tooltip"
          >
            <span className="tooltip-content">{_('Shared survey')}</span>
          </ReactTooltip>
        </>
      );
    }

    return null;
  }

  render() {
    const {
      survey,
      survey: {
        id,
        title,
        updatedAt,
        startedAt,
        endedAt,
        type,
        languages,
        isSectorial,
        organization,
        penetrationRate,
        nbResponses,
        csScope,
        scope,
        translationScore,
        isAnonymous,
      },
      lng,
      auth,
      openSurveyModal,
    } = this.props;

    const status = this.getStatus();
    const colorBorder = this.getColorBorder(status);
    const arrayLanguages = languages?.split(',') ?? [];

    return (
      <div className={`row list-item ${colorBorder}`} key={id}>
        <div className="medium-3">
          <div className="list-item__header">
            {getTextByLanguage(title, lng)}
          </div>
        </div>
        <div className="medium-3 p-r-s">
          <div className="list-item__sub-header">
            <i className="icon-left icon-clock m-r-xs" />
            <span>{`${_('updatedAt')} `}</span>
            <span className="m-r-xs">
              {moment(updatedAt)
                .locale(lng)
                .format('dddd L LT')}
            </span>
          </div>
          {type == 'PORTAL' && (
            <>
              <div className="list-item__sub-header">
                <i className="icon-left icon-clock m-r-xs color-success" />
                <span>{`${_('startedAt')} `}</span>
                <span className="m-r-xs">
                  {moment(startedAt)
                    .locale(lng)
                    .format('dddd L LT')}
                </span>
              </div>
              <div className="list-item__sub-header">
                <i className="icon-left icon-clock m-r-xs color-warning" />
                <span>{`${_('endedAt')} `}</span>
                <span className="m-r-xs">
                  {moment(endedAt)
                    .locale(lng)
                    .format('dddd L LT')}
                </span>
              </div>
            </>
          )}
          <div className="list-item__sub-header">
            <i className="icon-left icon-globe m-r-xs" />
            {arrayLanguages?.map((lng, index) => (
              <span key={lng}>
                {_(TAMTAM_API_LANGUAGES[lng])}
                {arrayLanguages.length > 1 &&
                  `(${parseInt(parseInt(translationScore?.[lng] ?? 0))}%)`}
                {index !== arrayLanguages.length - 1 ? ', ' : ''}
              </span>
            ))}
          </div>
        </div>
        <div className="medium-2 flex-container">
          <GroupsCell
            groups={[
              _(type),
              ['SATISFACTION', 'PORTAL'].includes(type) ? _(scope) : null,
              isAnonymous ? _('Anonymous survey') : null,
              isSectorial ? _('Consolidated survey') : null,
              organization?.id !== auth.currentClient?.id ? _('Public') : null,
              _(csScope),
            ].filter(item => item)}
          />
        </div>
        <div className="medium-1">{organization?.abbreviation}</div>
        <div className="medium-1">
          {type !== 'QUIZ' && (
            <div className="list-item__sub-header flex-column-center m-b-xxs">
              <span
                className="list-item__tag p-x-s"
                data-for={`responses-${id}`}
                data-tip={_('Responses')}
              >
                {nbResponses}
              </span>
              <ReactTooltip id={`responses-${id}`} type="dark" place="bottom" />
            </div>
          )}
          {penetrationRate && ['QUIZ', 'PORTAL'].indexOf(type) === -1 && (
            <div className="list-item__sub-header flex-column-center">
              <span
                className="list-item__tag p-x-s"
                data-for={`penetration-rate-${id}`}
                data-tip={_('penetration_rate')}
              >
                {`${penetrationRate} %`}
              </span>
              <ReactTooltip
                id={`penetration-rate-${id}`}
                type="dark"
                place="bottom"
              />
            </div>
          )}
        </div>
        <ActionsCell
          survey={survey}
          auth={auth}
          openSurveyModal={openSurveyModal}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lng: state.params.lng,
  auth: state.auth,
});

export default connect(mapStateToProps)(SurveyItem);
