import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import _ from 'i18n';
import moment from 'moment';
import { getSurveyLinkPreview } from 'surveyUtils';
import { getAuthorizations } from 'authorizations';
import SurveyActions from '../SurveyActions';
import Play from '../../icons/Play';
import Open from '../../icons/Open';
import Stats from '../../icons/Stats';

class ActionsCell extends Component {
  handleEdit = () => {
    const {
      survey,
      auth: { user, currentClient, loggedAs },
      history,
    } = this.props;
    const { edit } = getAuthorizations(survey, user, currentClient, loggedAs);
    if (!edit.disable) {
      history.push(`/surveys/${survey.slug}/create?source=list`);
    }
  };

  handleClick = event => {
    if (
      this.wrapperActions &&
      !this.wrapperActions.current.contains(event.target) &&
      !event.target.closest('.reveal, .ReactModalPortal')
    ) {
      this.handleEdit();
    }
  };

  handlePlay = () => {
    this.props.history.push(getSurveyLinkPreview(this.props.survey, true));
  };

  getStatusClassColor = status => {
    switch (status) {
      case 'PENDING':
        return 'bg-draft';
      case 'STARTED':
        return 'bg-success';
      case 'CLOSED':
        return 'bg-warning';
      case 'ENDED':
        return 'bg-warning';
      default:
        return '';
    }
  };

  renderStatus = () => {
    const {
      type,
      startedAt,
      endedAt,
      organizationInstance,
    } = this.props.survey;
    const status = organizationInstance?.status ?? 'PENDING';

    const now = moment();
    let statusPortal = 'PENDING';

    switch (type) {
      case 'PORTAL':
        if (now.isBetween(moment(startedAt), moment(endedAt), null, [])) {
          statusPortal = 'STARTED';
        } else if (now.isAfter(endedAt)) {
          statusPortal = 'ENDED';
        }
        return (
          <div className="list-item__status">
            <i className={this.getStatusClassColor(statusPortal)} />
            {_(statusPortal)}
          </div>
        );
      case 'QUIZ':
        return null;
      default:
        return (
          <div className="list-item__status">
            <i className={this.getStatusClassColor(status)} />
            {_(status)}
          </div>
        );
    }
  };

  render() {
    const {
      survey,
      auth: { user, currentClient, loggedAs },
      openSurveyModal,
    } = this.props;
    const {
      edit: { disable, hide },
    } = getAuthorizations(survey, user, currentClient, loggedAs);

    const hiddens = ['edit', 'preview', 'stats'];
    if (survey.type === 'QUIZ') {
      hiddens.push('play');
    }

    return (
      <div className="medium-2">
        <div>
          <div className="list-item__status">{this.renderStatus()}</div>
          <div className="list-item__actions">
            <div>
              <Link
                to={
                  survey.type == 'QUIZ'
                    ? `/results/${survey.id}`
                    : `/stats/${survey.slug}?tab=stats&organization=${currentClient?.id ?? 0}`
                }
              >
                <Stats />
              </Link>
            </div>
            {/* <div>
              <Link to={`/survey/preview/${survey.slug}`} target="_blank">
                <i className="icomoon icon-tt-eye color-primary" />
              </Link>
            </div> */}
            {!hide && (
              <div
                onClick={this.handleEdit}
                className={disable ? 'disabled-link' : ''}
              >
                <i
                  className={`icomoon ${
                    survey.organization?.id == currentClient?.id
                      ? 'icon-tt-edit'
                      : 'icon-tt-settings'
                  }`}
                />
              </div>
            )}
            <div>
              <Link
                to={getSurveyLinkPreview(this.props.survey, true, currentClient?.id)}
                target="_blank"
              >
                {survey.type === 'QUIZ' ? <Play /> : <Open />}
              </Link>
            </div>
            <SurveyActions
              survey={survey}
              editSurvey={this.handleEdit}
              visible={true}
              lng={this.lng}
              hidden={hiddens}
              activeClassName=""
              openSurveyModal={openSurveyModal}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ActionsCell);
