import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toolbar from 'toolbar/Toolbar';
import Settings from 'toolbar/Settings';
import SurveySettings from 'toolbar/SurveySettings';
// import Themes from 'toolbar/Themes';
import SurveyDesign from 'toolbar/survey-design/SurveyDesign';
import _ from 'i18n';
import { persistSurvey } from 'thunks';
import {
  setSettingsCurrentTab,
  validateSurvey,
  setShowSettingsSlider,
  setTTPDialogCustomData,
} from 'actions';
import { NotificationManager } from 'react-notifications';
import { Link, withRouter } from 'react-router-dom';
import { SURVEY_BUILDER_TABS, DIALOG_TYPES } from 'constants';
import GenericEmailing from '../toolbar/GenericEmailing';
import ReactTooltip from 'react-tooltip';
import Library from '../toolbar/library/Library';
import { compose } from 'redux';

const getTabs = (editAllowed, survey) => [
  {
    value: SURVEY_BUILDER_TABS.LIBRARY,
    // icon: 'icomoon-icon icon-tt-template',
    // text: _('Library'),
    // icon: 'icomoon-icon icon-tt-profil',
    icon: 'icomoon-icon icon-tt-question',
    text: _('Profilage'),
    disabled: !editAllowed,
    hide: ['QUIZ', 'PORTAL'].includes(survey.type),
  },
  // {
  //   value: SURVEY_BUILDER_TABS.EDITOR,
  //   icon: 'icomoon-icon icon-tt-question',
  //   text: _('Questions'),
  //   disabled: !editAllowed,
  // },
  {
    value: SURVEY_BUILDER_TABS.SURVEY_SETTINGS,
    icon: 'icomoon-icon icon-tt-settings',
    text: _('Settings'),
    disabled: !editAllowed,
  },
  // {
  //   value: SURVEY_BUILDER_TABS.THEMES,
  //   icon: 'icomoon-icon icon-tt-colors',
  //   text: _('themes'),
  //   disabled: !editAllowed
  // },
  {
    value: SURVEY_BUILDER_TABS.SURVEY_THEMES,
    icon: 'icomoon-icon icon-tt-colors',
    text: _('themes'),
    disabled: !editAllowed,
    hide: ['QUIZ'].includes(survey.type),
  },
  {
    value: SURVEY_BUILDER_TABS.MAILS,
    icon: 'icomoon-icon icon-tt-envelope-open',
    text: _('Emails'),
    disabled: !survey || !survey.id,
    hide: ['QUIZ', 'PORTAL'].includes(survey.type),
  },
];

class EditorToolbar extends Component {
  constructor(props) {
    super(props);

    this.setCurrentTab = this.setCurrentTab.bind(this);

    this.closeSlider = () => this.props.setShowSettingsSlider(false);
    this.openSlider = () => this.props.setShowSettingsSlider(true);
  }

  shouldComponentUpdate(nextProps) {
    const { currentTab, surveys, editAllowed, showSettingsSlider } = this.props;
    const {
      survey: { id, organizationSurvey, type },
      fetching,
    } = surveys;

    return (
      currentTab != nextProps.currentTab ||
      id != nextProps.surveys.survey.id ||
      organizationSurvey != nextProps.surveys.survey.organizationSurvey ||
      fetching != nextProps.surveys.fetching ||
      editAllowed != nextProps.editAllowed ||
      showSettingsSlider != nextProps.showSettingsSlider ||
      type != nextProps.surveys.survey.type
    );
  }

  setCurrentTab(tab) {
    this.props.setSettingsCurrentTab(tab);
  }

  saveSurvey = () => {
    const {
      surveys: { survey },
    } = this.props;
    const isNew = !survey.id;
    this.props.validateSurvey();
    this.props.persistSurvey(survey).then(
      res => {
        const { slug } = res.value.data.data;
        const linkSurvey = `/surveys/${slug}/create${location.search}`;

        if (isNew) {
          if (window.history.pushState) {
            window.history.pushState({}, '', linkSurvey);
          } else {
            document.location.href = linkSurvey;
          }
        }

        this.onSuccess(res);
      },
      err => this.onFailure(err),
    );
  };

  closeBuilder = () => {
    const params = new URLSearchParams(location.search);
    const source = params.get('source');
    let goTo = '/';

    if (source === 'list') {
      goTo = '/surveys';
    } else {
      goTo = '/surveys/type/selector';
    }

    this.props.setTTPDialogCustomData({
      approvedAction: () => this.props.history.push(goTo),
      message: _('Did you save all the changes?'),
      title: _('Confirm action'),
      type: DIALOG_TYPES.INFO,
    });
  };

  onSuccess(res) {
    if (res.value instanceof Error) {
      this.onFailure();
    } else {
      NotificationManager.success(_('save_survey_success'), _('Saved'));
    }
  }

  onFailure() {
    NotificationManager.error(_('errorOccured'), _('error'));
  }

  renderSlider() {
    const {
      currentTab,
      surveys: { survey },
      currentClient,
    } = this.props;
    switch (currentTab) {
      // case SURVEY_BUILDER_TABS.EDITOR:
      //   return <Toolbar closeSlider={this.closeSlider} />;
      case SURVEY_BUILDER_TABS.SETTINGS:
        return <Settings closeSlider={this.closeSlider} />;
      case SURVEY_BUILDER_TABS.SURVEY_SETTINGS:
        return <SurveySettings closeSlider={this.closeSlider} />;
      // case SURVEY_BUILDER_TABS.THEMES: return <Themes closeSlider={this.closeSlider} />;
      case SURVEY_BUILDER_TABS.SURVEY_THEMES:
        return <SurveyDesign closeSlider={this.closeSlider} />;
      case SURVEY_BUILDER_TABS.MAILS:
        return (
          <GenericEmailing survey={survey} currentClient={currentClient} />
        );
      case SURVEY_BUILDER_TABS.LIBRARY:
        return (
          <Library
            survey={survey}
            currentClient={currentClient}
            closeSlider={this.closeSlider}
          />
        );
      default:
        return null;
    }
  }

  renderButtons() {
    const {
      surveys: { fetching, survey },
      editAllowed,
      currentTab,
      showSettingsSlider,
    } = this.props;

    return getTabs(editAllowed, survey).map((tab, key) => {
      if (tab.hide) return null;
      return (
        <li
          className={`toolbar-list-item main ${
            tab.disabled || fetching ? 'disabled-link' : ''
          }`}
          key={key}
          onClick={
            !tab.disabled && !fetching
              ? this.setCurrentTab.bind(this, tab.value)
              : null
          }
        >
          <div
            className={`block-wrapper block-wrapper--no-spacing ${
              currentTab === tab.value && showSettingsSlider ? 'selected' : ''
            }`}
          >
            <div className="block-icon">
              <div className="_icon ">
                <span>
                  <i className={tab.icon} />
                </span>
              </div>
            </div>
            <div className="block-title">{tab.text}</div>
          </div>
        </li>
      );
    });
  }

  render() {
    const {
      fetching,
      survey: { id, type, slug },
    } = this.props.surveys;
    const { currentTab, editAllowed, currentClient } = this.props;
    const {
      SETTINGS,
      THEMES,
      SURVEY_THEMES,
      EDITOR,
      PREVIEW,
      SURVEY_SETTINGS,
      MAILS,
      LIBRARY,
    } = SURVEY_BUILDER_TABS;
    let className = 'toolbar-slider';

    if (![EDITOR, PREVIEW].includes(currentTab) && this.props.showSettingsSlider) {
      className += ' toolbar-slider--open';
    }
    if (
      [SETTINGS, SURVEY_SETTINGS, THEMES, SURVEY_THEMES, LIBRARY].indexOf(
        currentTab,
      ) !== -1
    ) {
      className += ' toolbar-slider--medium';
    }

    if (MAILS === currentTab) {
      className += ' toolbar-slider--big';
    }

    const linkStats =
      type == 'QUIZ' ? `/results/${id}` : `/stats/${slug}?tab=stats&organization=${currentClient?.id ?? 0}`;

    return (
      <div className="editor-toolbar">
        <div className="toolbar-content">
          <div className="toolbar-content__logo">
            <span className="menu__logo">
              <img alt="" src="/img/pictos/light-logo-tamtam-survey.svg" />
            </span>
          </div>
          <ul className={`toolbar-content--list ${id == null ? 'p-t-xl' : ''}`}>
            {id != null && (
              <li className="external-links">
                <Link
                  to={linkStats}
                  target="_blank"
                  data-tip
                  data-for={`link-stats-${id}`}
                >
                  <i className="icomoon icon-tt-stats" />
                </Link>
                <Link
                  to={`/survey/preview/${slug}`}
                  target="_blank"
                  data-tip
                  data-for={`link-preview-${id}`}
                >
                  <i className="icomoon icon-tt-eye" />
                </Link>
                <ReactTooltip
                  id={`link-stats-${id}`}
                  multiline={true}
                  className="react-tooltip"
                >
                  <span className="tooltip-content">
                    {type == 'QUIZ' ? _('Results') : _('Stats')}
                  </span>
                </ReactTooltip>
                <ReactTooltip
                  id={`link-preview-${id}`}
                  multiline={true}
                  className="react-tooltip"
                >
                  <span className="tooltip-content">{_('Preview')}</span>
                </ReactTooltip>
              </li>
            )}
            {this.renderButtons()}
          </ul>
          <div className="toolbar-content__style--button">
            {editAllowed && MAILS !== currentTab ? (
              <>
                <div className="s-toolbar__title s-toolbar__button">
                  <button
                    type="button"
                    disabled={fetching}
                    className="s-toolbar__button--full-width s-toolbar__button--raduis btn-ctrl__btn"
                    onClick={this.saveSurvey}
                  >
                    {_('Save')}
                  </button>
                </div>
                <div className="s-toolbar__title s-toolbar__button">
                  <button
                    type="button"
                    disabled={fetching}
                    className="s-toolbar__button--full-width s-toolbar__button--raduis btn-ctrl__btn"
                    onClick={this.setCurrentTab.bind(
                      this,
                      [THEMES, PREVIEW].indexOf(currentTab) !== -1
                        ? EDITOR
                        : PREVIEW,
                    )}
                  >
                    {[SURVEY_THEMES, THEMES, PREVIEW].indexOf(currentTab) !== -1
                      ? _('Editor')
                      : _('Preview')}
                  </button>
                </div>
              </>
            ) : (
              <div className="s-toolbar__title s-toolbar__button">
                <button
                  type="button"
                  disabled={fetching}
                  className="s-toolbar__button--full-width s-toolbar__button--raduis btn-ctrl__btn"
                  onClick={this.setCurrentTab.bind(this, PREVIEW)}
                >
                  {_('Preview')}
                </button>
              </div>
            )}
            <div className="s-toolbar__title s-toolbar__button">
              <button
                type="button"
                className="s-toolbar__button--full-width s-toolbar__button--raduis btn-ctrl__btn"
                onClick={this.closeBuilder}
              >
                {_('Close')}
              </button>
            </div>
          </div>
        </div>
        <div className={className}>{this.renderSlider()}</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  surveys: state.surveys.list,
  currentTab: state.surveys.list.toolbarTab,
  showSettingsSlider: state.surveys.list.showSettingsSlider,
  currentClient: state.auth.currentClient,
});

const mapDispatchToProps = {
  persistSurvey,
  setSettingsCurrentTab,
  validateSurvey,
  setShowSettingsSlider,
  setTTPDialogCustomData,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
)(EditorToolbar);
