import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  setSurveyResponse,
  startSurvey,
  updateAnswer,
  addLastUpdatedAnswer,
  initSurveyResponse,
} from 'actions';
import { fetchSurveyResponse } from 'thunks';
import _ from 'i18n';
import __ from 'lodash';
import { stateToHTML } from 'draft-js-export-html';
import ReactHtmlParser from 'react-html-parser';
import { getFirstNotAnsweredQuestion } from 'surveyUtils';
import { getAutoGeneratedAnswer } from 'answer';
import { getAllQuestionsOfSurvey } from 'question';
import TTButton from '../common/TTButton';

class SurveyTitlePreview extends Component {
  componentDidMount() {
    const element = document.querySelector('.survey-builder');
    if (element == null) {
      document.addEventListener('keydown', this.handleEnterButton, false);
    }
    this.props.scrollToTop();
    this.props.initSurveyResponse();
    this.fetchUserResponse();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEnterButton, false);
  }

  fetchUserResponse = () => {
    const {
      user,
      survey,
      fakeResponse,
      client,
      organizationInstance,
      currentClient,
    } = this.props;
    const { id, isAnonymous, type, latestInstance } = survey;
    if (id != null && !fakeResponse) {
      const surveyResponse = {
        survey: id,
        instance:
          organizationInstance?.instance?.id ??
          (type === 'PORTAL' ? latestInstance?.id ?? 0 : 0),
        userParticipant: user.id,
        organization:
          organizationInstance?.organization?.id ??
          (type === 'PORTAL' ? currentClient?.id ?? 0 : 0),
        client,
        isAnonymous,
      };
      this.props.setSurveyResponse(surveyResponse);
      this.props
        .fetchSurveyResponse(
          surveyResponse.instance,
          surveyResponse.organization,
          client,
        )
        .then(res => {
          const { answers } = res.value.data.data;
          const autoFilledAnswers = this.autoFillQuestions(answers);

          this.props.setMissingQuestion(
            getFirstNotAnsweredQuestion(survey, [
              ...answers,
              ...autoFilledAnswers,
            ]),
          );
        })
        .catch(err => {
          const autoFilledAnswers = this.autoFillQuestions([]);

          this.props.setMissingQuestion(
            getFirstNotAnsweredQuestion(survey, autoFilledAnswers),
          );
          return err;
        });
    }
  };

  autoFillQuestions = answers => {
    const autoFilledAnswers = [];
    const questions = getAllQuestionsOfSurvey(this.props.survey);
    const answeredQuestionIds = new Map(
      answers.map(({ question }) => [question.id, question]),
    );
    const profilingQuestions = questions
      .filter(
        ({ profilingType }) => profilingType && profilingType.trim() != '',
      )
      .filter(({ id }) => answeredQuestionIds.get(id) == null);

    profilingQuestions.forEach(question => {
      const answer = getAutoGeneratedAnswer(question, this.props.user);

      if (answer) {
        autoFilledAnswers.push(answer);
        this.props.updateAnswer(answer);
        this.props.addLastUpdatedAnswer(question.id);
      }
    });

    /** Simulate api respone format */
    return autoFilledAnswers.map(answer => ({
      ...answer,
      question: { id: answer.question },
    }));
  };

  startSurvey = () => {
    const {
      survey: { id },
      fakeResponse,
      readOnly,
    } = this.props;

    this.props.startSurvey(
      true,
      id == null || fakeResponse || readOnly || false,
    );
  };

  handleEnterButton = e => {
    if (e.key == 'Enter' && !this.props.responseFetching) {
      this.startSurvey();
    }
  };

  renderLogo() {
    const { organization } = this.props.survey;

    return (
      <div className="preview__container__title--image">
        <img
          alt=""
          src={organization?.avatarUrl ?? '/img/pictos/logo.svg'}
          className="img-meduim"
        />
      </div>
    );
  }

  render() {
    const { title, description, language, responseFetching } = this.props;

    return (
      <>
        <div className="survey-title-preview flex-column-center column small-11">
          <div className="preview__container__title">
            {this.renderLogo()}
            <h1 className="preview__container__title--text survey-font__title survey-font--center">
              {title ? title[language] : ''}
            </h1>
          </div>
          <span className="tt-button-group m-t-m">
            <TTButton
              disabled={responseFetching}
              text={_('Start')}
              className="rounded full survey-main-content-gradient-background-color"
              upperCase={true}
              onClick={this.startSurvey}
              fontSize="20px"
            />
            <span className="tt-button-help">
              {_('press')} <strong>[ENTER]</strong>
            </span>
          </span>
          {!__.isEmpty(description) && !__.isEmpty(description[language]) && (
            <div className="survey-description-wrapper m-t-l">
              <div className="survey-desc">
                {ReactHtmlParser(
                  stateToHTML(description[language].getCurrentContent()),
                )}
              </div>
            </div>
          )}
        </div>
        <div className="powered-by">
          <span>Powered By</span>
          <img
            className="logo"
            alt=""
            src="/img/pictos/full-logo-horizontal.svg"
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  currentClient: state.auth.currentClient,
  language: state.surveys.list.language,
  organizationInstance: state.surveyResponse.organizationInstance,
  responseFetching: state.surveyResponse.fetching,
  answers: state.surveyResponse.answers,
});

const mapDispatchToProps = {
  setSurveyResponse,
  startSurvey,
  fetchSurveyResponse,
  updateAnswer,
  addLastUpdatedAnswer,
  initSurveyResponse,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyTitlePreview);
