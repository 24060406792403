import { autoSaveSurveyResponse } from 'thunks';

export const initSurveyResponse = () => ({
  type: 'INIT_SURVEY_RESPONSE',
});
export const initSurveyResponseOrganizationInstance = () => ({
  type: 'INIT_SURVEY_RESPONSE_ORGANIZATION_INSTANCE',
});
export const addRequiredQuestionId = id => ({
  type: 'ADD_REQUIRED_QUESTION_ID',
  id,
});
export const removeRequiredQuestionId = id => ({
  type: 'REMOVE_REQUIRED_QUESTION_ID',
  id,
});

let timer = null; // Timer to check the new responses each {TIME} seconds and auto-save them.
const TIME = 10; // Seconds
export const startSurvey = (startSurvey, isFakeAnswer) => (dispatch) => {
  if (!isFakeAnswer && isFakeAnswer != null) {
    clearInterval(timer);
    timer = setInterval(() => dispatch(autoSaveSurveyResponse()), 1000 * TIME);
  }

  dispatch(
    {
      type: 'START_SURVEY',
      startSurvey,
    },
  );
};

export const stopAutoSave = () => {
  if (timer != null) {
    clearInterval(timer);
  }
};

export const completeSurvey = (completeSurvey) => {
  stopAutoSave();

  return {
    type: 'COMPLETE_SURVEY',
    completeSurvey,
  };
};
export const setSurveyResponse = surveyResponse => ({
  type: 'SET_SURVEY_RESPONSE',
  surveyResponse,
});
export const createSurveyResponse = resp => ({
  type: 'SAVE_SURVEY_RESPONSE',
  payload: resp,
});
export const updateAnswer = answer => ({
  type: 'UPDATE_ANSWER',
  answer,
});
export const addAnswerValidationErrors = (questionId, error) => ({
  type: 'ANSWER_VALIDATION_ERROR',
  questionId,
  error,
});
export const resetAnswerValidationErrors = questionId => ({
  type: 'RESET_ANSWER_VALIDATION_ERROR',
  questionId,
});
export const addLastUpdatedAnswer = questionId => ({
  type: 'ADD_LAST_UPDATED_ANSWER',
  questionId,
});
export const resetLastUpdatedAnswers = updatedAnswers => ({
  type: 'RESET_LAST_UPDATED_ANSWERS',
  updatedAnswers,
});
export const deleteAnswer = answerId => ({
  type: 'DELETE_ANSWER',
  payload: answerId,
});
export const fetchSurveyResponse = resp => ({
  type: 'FETCH_SURVEY_RESPONSE',
  payload: resp,
});
export const fetchResponses = resp => ({
  type: 'FETCH_RESPONSES',
  payload: resp,
});

export const subscribeToStats = resp => ({
  type: 'SURVEY_RESPONSE_SUBSCRIBE_TO_STATS',
  payload: resp,
});

export const setSubscribedToStats = subscribedToStats => ({
  type: 'SET_SUBSCRIBED_TO_STATS',
  subscribedToStats,
});

// Filters
export const initializeResponsesFilter = pageNumber => ({
  type: 'INITIALIZE_RESPONSES_FILTER',
  pageNumber,
});

export const setResponsesPaginationPage = pageNumber => ({
  type: 'SET_RESPONSES_FILTER_PAGINATION_PAGE',
  pageNumber,
});

export const setResponsesPageSize = pageSize => ({
  type: 'SET_RESPONSES_FILTER_PAGE_SIZE',
  pageSize,
});

// SORT:
export const setResponsesSortConfig = (columnKey, sortDirection) => ({
  type: 'SET_RESPONSES_FILTER_SORT_CONFIG',
  config: {
    columnKey,
    sortDirection,
  },
});
